import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';

import * as ol from 'ol';
import * as ol_control from 'ol/control';
import * as ol_coordinate from 'ol/coordinate';
import * as ol_extent from 'ol/extent';
import * as ol_layer from 'ol/layer';
import * as ol_proj from 'ol/proj';
import * as ol_source from 'ol/source';

import LayerSwitcher, {
    BaseLayerOptions,
    GroupLayerOptions,
} from 'ol-layerswitcher';
import React, { useEffect, useRef, useState } from 'react';
import {
    createFocusNodeCaption,
    createHoverNodeCaption,
    setNodeCaption,
} from 'src/components/map/NodeCaption';
import {
    createNodeMarker,
    setDefaultStyle,
    setOnFocusStyle,
    setOnHoverFocusedStyle,
    setOnHoverStyle,
} from 'src/components/map/Node';

import { BORDER } from 'src/constants/colors';
import { Circle } from 'ol/geom';
import { FeatureLike } from 'ol/Feature';
import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import { Node } from '@nne-viz/common';
import SourceOSM from 'ol/source/OSM';
import SourceStamen from 'ol/source/Stamen';
import TileSource from 'ol/source/XYZ';
import styled from 'styled-components';

/**
 * @description style of the map
 */
const Container = styled.div`
    border: solid;
    border-color: ${BORDER};
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    flex-align: center;
    height: 99.5%;
    width: 100%;
`;
/**
 * @description props of the map component
 * @interface Props
 */
interface Props {
    /**
     * @description node
     * @type {Node}}
     */
    nodes: Node[];
    /**
     * @function setSelectedNode
     * @param {Node} node
     * @returns {void}
     */
    setSelectedNode: (node: Node) => void;
}
/**
 * @description map functional component using open layers
 * @type {React.FC}
 */
const Map: React.FC<Props> = ({ nodes, setSelectedNode }: Props) => {
    const [map, setMap] = useState<ol.Map | undefined>(undefined);
    const [hoverNode, setHoverNode] = useState<Node | undefined>(undefined);
    // focus node for the map
    const [focusNode, setFocusNode] = useState<Node | undefined>(undefined);
    // Create a ref to the div that will hold the map
    const mapRef = useRef<HTMLDivElement>(null);
    const hoveredNodeCaption = useRef<HTMLElement>(createHoverNodeCaption());
    const focusedNodeCaption = useRef<HTMLElement>(createFocusNodeCaption());

    // useEffect is runs when the element in the list is changed AND when accessing the component
    useEffect(() => {
        if (!mapRef.current) return;
        // Create a new map object
        const map: ol.Map = new ol.Map({
            // Set the target of the map to the div created with useRef
            target: mapRef.current,
            layers: [
                new LayerGroup({
                    title: 'Layers: ',
                    layers: [
                        new LayerTile({
                            title: 'Thunderforest transport',
                            type: 'base',
                            visible: false,
                            source: new TileSource({
                                url: 'https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=eaee37af233343e5a18215249785beed',
                            }),
                        } as BaseLayerOptions),
                        new LayerTile({
                            title: 'Stamen water color',
                            type: 'base',
                            visible: false,
                            source: new SourceStamen({ layer: 'watercolor' }),
                        } as BaseLayerOptions),
                        new LayerTile({
                            title: 'Stamen toner',
                            type: 'base',
                            visible: false,
                            source: new SourceStamen({ layer: 'toner' }),
                        } as BaseLayerOptions),
                        new LayerTile({
                            title: 'Stamen terrain',
                            type: 'base',
                            visible: false,
                            source: new SourceStamen({ layer: 'terrain' }),
                        } as BaseLayerOptions),
                        new LayerTile({
                            title: 'OSM',
                            type: 'base',
                            visible: true,
                            source: new SourceOSM(),
                        } as BaseLayerOptions),
                        new LayerTile({
                            title: 'OpenTopoMap',
                            type: 'base',
                            visible: false,
                            source: new TileSource({
                                url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png',
                            }),
                        } as BaseLayerOptions),
                        new LayerTile({
                            title: 'CartoDB',
                            type: 'base',
                            visible: false,
                            source: new TileSource({
                                url: 'https://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
                            }),
                        } as BaseLayerOptions),
                    ],
                } as GroupLayerOptions),
                new ol_layer.Vector({
                    source: new ol_source.Vector(),
                    className: 'nodes',
                }),
            ],
            view: new ol.View({
                center: ol_proj.transform(
                    [13.120684, 65.002734],
                    'EPSG:4326',
                    'EPSG:3857'
                ),
                zoom: 4.5,
            }),
            controls: ol_control.defaults().extend([
                new ol_control.MousePosition({
                    coordinateFormat: ol_coordinate.createStringXY(2),
                    projection: 'EPSG:4326',
                }),
                new LayerSwitcher({
                    reverse: true,
                    groupSelectStyle: 'group',
                }),
            ]),
            overlays: [
                new ol.Overlay({
                    id: 0,
                    element: hoveredNodeCaption.current,
                    autoPan: false,
                }),
                new ol.Overlay({
                    id: 1,
                    element: focusedNodeCaption.current,
                    autoPan: false,
                }),
            ],
        });
        addNodes(map, nodes);
        // Every time pointer move on the map
        map.on('pointermove', function (event) {
            // Get the feature at the mouse position
            const featureLike: FeatureLike | undefined =
                map.forEachFeatureAtPixel(event.pixel, (feature) => {
                    return feature;
                });
            // If there is a feature like with a node ID
            if (featureLike?.getId() !== undefined) {
                const nodeId = featureLike.getId();
                const node = nodes.find((node) => node.id === nodeId);
                setHoverNode(node);
            } else {
                setHoverNode(undefined);
            }
        });

        map.on('click', function (event) {
            // Get the feature at the mouse position
            const featureLike: FeatureLike | undefined =
                map.forEachFeatureAtPixel(event.pixel, (feature) => {
                    return feature;
                });
            // If there is a feature like with a node ID
            if (featureLike?.getId() !== undefined) {
                const nodeId = featureLike.getId();
                const node = nodes.find((node) => node.id === nodeId);
                setFocusNode(node);
            } else {
                setFocusNode(undefined);
            }
        });
        setMap(map);
        return () => {
            // Destroy the map object when the component unmounts
            map.dispose();
            setMap(undefined);
        };
    }, []);

    useEffect(() => {
        if (map !== undefined && focusNode !== undefined) {
            // retrieve feature for the node
            const feature: ol.Feature = getFeature(map, focusNode);
            setOnFocusStyle(feature);
            // zoom to the extent of the clicked feature
            const geometry: Circle = feature.getGeometry() as Circle;
            const coordinate =
                geometry.getCoordinates() as unknown as ol_coordinate.Coordinate;
            // set the position of the caption to the position of the node
            map.getOverlayById(1).setPosition(coordinate);
            // set the caption HTML
            setNodeCaption(focusedNodeCaption.current, focusNode);
            // remove hover caption
            hoveredNodeCaption.current.style.display = 'none';
            // display the focus caption
            focusedNodeCaption.current.addEventListener(
                'click',
                onClickFocusedNodeCaption
            );
            focusedNodeCaption.current.style.display = 'block';
            map.getView().fit(ol_extent.buffer(geometry.getExtent(), 20000), {
                duration: 1000,
            });
        }
        return () => {
            if (map !== undefined && focusNode !== undefined) {
                // retrieve feature for the old node
                const feature: ol.Feature = getFeature(map, focusNode);
                // set the feature back to default style
                setDefaultStyle(feature, focusNode);
                // hide caption
                focusedNodeCaption.current.style.display = 'none';
                focusedNodeCaption.current.removeEventListener(
                    'click',
                    onClickFocusedNodeCaption
                );
            }
        };
    }, [focusNode]);

    useEffect(() => {
        if (map !== undefined && hoverNode !== undefined) {
            if (focusNode === undefined) {
                const feature = getFeature(map, hoverNode);
                setOnHoverStyle(feature);
                // retrieve coordinate of the node
                const geometry: Circle = feature.getGeometry() as Circle;
                const coordinate =
                    geometry.getCoordinates() as unknown as ol_coordinate.Coordinate;
                // Set the position of the caption to the position of the node
                map.getOverlayById(0).setPosition(coordinate);
                // Set the caption HTML
                setNodeCaption(hoveredNodeCaption.current, hoverNode);
                // Display the caption
                hoveredNodeCaption.current.style.display = 'block';
            } else {
                if (hoverNode.id === focusNode.id) {
                    const feature = getFeature(map, hoverNode);
                    setOnHoverFocusedStyle(feature);
                }
            }
        }
        return () => {
            if (map !== undefined && hoverNode !== undefined) {
                if (focusedNodeCaption.current.style.display === 'none') {
                    const feature = getFeature(map, hoverNode);
                    // Set the feature back to default style
                    setDefaultStyle(feature, hoverNode);
                    // Hide the caption
                    hoveredNodeCaption.current.style.display = 'none';
                } else if (
                    focusNode !== undefined &&
                    hoverNode.id === focusNode.id
                ) {
                    const feature = getFeature(map, focusNode);
                    // Set the feature back to default style
                    setOnFocusStyle(feature);
                }
            }
        };
    }, [hoverNode]);

    /**
     * @function addNodes
     * @description add nodes to the map
     * @param {ol.Map} map
     * @param {Node[]} nodes
     * @returns {void}
     */
    const addNodes = (map: ol.Map, nodes: Node[]): void => {
        const nodeLayer = map
            .getAllLayers()
            .filter(
                (layer) => layer.getClassName() === 'nodes'
            )[0] as ol_layer.Vector<ol_source.Vector>;
        nodes.forEach((node: Node) => {
            if (nodeLayer.getSource())
                nodeLayer.getSource()?.addFeature(createNodeMarker(node));
        });
    };

    const getFeature = (map: ol.Map, node: Node): ol.Feature => {
        const nodeLayer = map
            .getAllLayers()
            .filter(
                (layer) => layer.getClassName() === 'nodes'
            )[0] as ol_layer.Vector<ol_source.Vector>;
        return nodeLayer.getSource()?.getFeatureById(node.id) as ol.Feature;
    };

    const onClickFocusedNodeCaption = () => {
        setSelectedNode(focusNode as Node);
    };

    return <Container ref={mapRef} />;
};

export default Map;

import { Route, Routes } from 'react-router-dom';

import Dashboard from 'src/scenes/comparative/dashboard';
import React from 'react';

/**
 * @type {React.FC}
 * @description Index containing subroutes for map scenes
 */
const Index: React.FC = () => (
    <Routes>
        <Route path="/" element={<Dashboard />} />
    </Routes>
);

export default Index;

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';

import React from 'react';

/**
 * @function error
 * @description Function to run when displaying an error in the toastr
 * @param {string} text The error message to display
 * @returns {void}
 */
const error = (text: string): void => {
    toast.error(text, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        toastId: 'error',
    });
};

const warning = (text: string): void => {
    toast.warning(text, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        toastId: 'warning',
    });
};

/**
 * @description Toastr component
 * @type {React.FC}
 */
const Toastr: React.FC = () => {
    return <ToastContainer />;
};

export default Toastr;

export const Toast = { error, warning };

import { BLACK, NODE_HOVER, SUBTHEME, Status } from 'src/constants/colors';
import { Circle, Fill, Stroke, Style } from 'ol/style';

import Feature from 'ol/Feature';
import { Node } from '@nne-viz/common';
import { fromLonLat } from 'ol/proj';
import ol_Feature from 'ol/Feature';
import ol_geom_Point from 'ol/geom/Point';

/**
 * @function createNodeMarker
 * @description create the marker for a node
 * @param {Node} node
 * @returns {ol_Feature}
 */
const createNodeMarker = (node: Node): ol_Feature => {
    const nodeFeature = new ol_Feature({
        geometry: new ol_geom_Point(
            fromLonLat([node.address.lon, node.address.lat])
        ),
    });
    nodeFeature.setId(node.id);
    setDefaultStyle(nodeFeature, node);
    return nodeFeature;
};
/**
 * @function setDefaultStyle
 * @description set the default style for the node marker
 * @param {Feature} feature
 * @param {Node} node
 */
const setDefaultStyle = (feature: Feature, node: Node) => {
    const style = new Style({
        image: new Circle({
            radius: 7,
            stroke: new Stroke({
                color: BLACK,
                width: 1,
            }),
            fill: new Fill({
                color: Status[node.status as keyof typeof Status],
            }),
        }),
    });

    feature.setStyle(style);
};
/**
 * @function setOnHoverStyle
 * @description set the style of the feature on mouse hover
 * @param {Feature} feature
 * @returns {void}
 */
const setOnHoverStyle = (feature: Feature): void => {
    const style = new Style({
        image: new Circle({
            radius: 12,
            stroke: new Stroke({
                color: BLACK,
                width: 1,
            }),
            fill: new Fill({
                color: SUBTHEME,
            }),
        }),
    });

    feature.setStyle(style);
};
/**
 * @function setOnFocusStyle
 * @description set the style of the feature when selected
 * @param {Feature} feature
 * @returns {void}
 */
const setOnFocusStyle = (feature: Feature): void => {
    const style = new Style({
        image: new Circle({
            radius: 12,
            stroke: new Stroke({
                color: BLACK,
                width: 1,
            }),
            fill: new Fill({
                color: SUBTHEME,
            }),
        }),
    });

    feature.setStyle(style);
};
/**
 * @function setOnHoverFocusedStyle
 * @description set the style when hover on selected feature
 * @param {Feature} feature
 * @returns {void}
 */
const setOnHoverFocusedStyle = (feature: Feature): void => {
    const style = new Style({
        image: new Circle({
            radius: 17,
            stroke: new Stroke({
                color: BLACK,
                width: 1,
            }),
            fill: new Fill({
                color: NODE_HOVER,
            }),
        }),
    });

    feature.setStyle(style);
};

export {
    createNodeMarker,
    setDefaultStyle,
    setOnHoverStyle,
    setOnFocusStyle,
    setOnHoverFocusedStyle,
};

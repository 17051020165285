import {
    GridContainer,
    GridSubContainer,
} from 'src/components/map/header/Containers';

import Caption from 'src/components/map/header/Caption';
import { MAX_WIDTH_PHONE_PORTRAIT } from 'src/constants/config';
import { Network } from '@nne-viz/common';
import NetworkSelect from 'src/components/select/NetworkSelect';
import React from 'react';
import UpRate from 'src/components/map/header/UpRate';
import styled from 'styled-components';

/**
 * @description map header style CSS
 */
const MapHeaderStyled = styled.div`
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 0 0.5em 0;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        display: flex;
        flex-direction: column;
    }
`;
/**
 * @description props for the map header component
 * @interface Props
 */
interface Props {
    /**
     * @description the network ID selected
     * @type {Network | undefined}
     */
    network: Network | undefined;
    /**
     * @function setNetworkId
     * @description funciton to set eh network id
     * @param {Network | undefined} network
     * @returns {void}
     */
    setNetwork: (network: Network | undefined) => void;
}
/**
 * @description the map header functional component
 * @type {React.FC}
 */
const MapHeader: React.FC<Props> = ({ network, setNetwork }) => {
    return (
        <MapHeaderStyled>
            <GridContainer grid_area="1 / 1 / 1 / 3">
                <GridSubContainer>
                    <NetworkSelect
                        isClearable={true}
                        text={'Operators'}
                        network={network}
                        setNetwork={setNetwork}
                    />
                </GridSubContainer>
            </GridContainer>
            <GridContainer grid_area={'1 / 3 / 1 / 5'}>
                <GridSubContainer>
                    <Caption text="Color code" />
                </GridSubContainer>
            </GridContainer>
            <GridContainer grid_area="1 / 5 / 1 / 6">
                <GridSubContainer>
                    <UpRate
                        networkId={
                            network?.id ? (network.id as number) : undefined
                        }
                    />
                </GridSubContainer>
            </GridContainer>
        </MapHeaderStyled>
    );
};

export default MapHeader;

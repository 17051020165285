import { BORDER } from 'src/constants/colors';
import React from 'react';
import TableHeaderCell from 'src/components/table/TableHeaderCell';
import TableHeaderSelectCell from 'src/components/table/TableHeaderSelectCell';
import styled from 'styled-components';

/**
 * @description props for table row
 * @interface TableHeaderProps
 */
interface TableHeaderProps {
    /**
     * @description length of column i.e. number of cells for column
     * @type {number}
     */
    column_length: number;
}

/**
 * @description Table row styled CSS
 */
const TableRow = styled.tr<TableHeaderProps>`
    display: grid;
    grid-template-columns: repeat(
        ${(props) => (props.column_length ? props.column_length : '1')},
        1fr
    );
`;

const TableHeaderStyled = styled.thead`
    border: solid;
    border-color: ${BORDER};
    border-width: 0 0 1px 0;
    display: grid;
`;

/**
 * @description Props for Table Header
 * @interface Props
 */

interface Props {
    values: string[];
    sort: (value: string) => void;
    isSelectable: boolean;
    isSelected?: boolean;
    isIndeterminate?: boolean;
    onSelect?: () => void;
}

const TableHeader: React.FC<Props> = ({
    values,
    sort,
    isSelectable,
    isSelected,
    isIndeterminate,
    onSelect,
}: Props) => {
    const renderSelectable = (
        isSelected: boolean,
        isIndeterminate: boolean,
        onSelect: () => void
    ) => {
        const values_to_th = values.map((value, index) => (
            <TableHeaderCell
                key={value}
                grid_column={`${index + 2} / ${index + 3}`}
                onClick={() => sort(value)}
                text={value}
            />
        ));

        return (
            <TableHeaderStyled>
                <TableRow column_length={values.length + 1}>
                    <TableHeaderSelectCell
                        grid_column={'1 / 2'}
                        onClick={onSelect}
                        isSelected={isSelected}
                        isIndeterminate={isIndeterminate}
                        onSelect={onSelect}
                    />
                    {values_to_th}
                </TableRow>
            </TableHeaderStyled>
        );
    };

    const render = () => {
        const values_to_th = values.map((value, index) => (
            <TableHeaderCell
                key={value}
                grid_column={`${index + 1} / ${index + 2}`}
                onClick={() => sort(value)}
                text={value}
            />
        ));

        return (
            <TableHeaderStyled>
                <TableRow column_length={values.length}>
                    {values_to_th}
                </TableRow>
            </TableHeaderStyled>
        );
    };

    if (
        isSelectable &&
        isSelected !== undefined &&
        onSelect !== undefined &&
        isIndeterminate !== undefined
    ) {
        return renderSelectable(isSelected, isIndeterminate, onSelect);
    } else {
        return render();
    }
};

export default TableHeader;

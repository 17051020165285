import { DARK_GRAY, LIGHT_GRAY, MAIN_THEME, MEDIUM_GRAY, WHITE } from 'src/constants/colors';
import {
    FONT_SIZE_DESKTOP,
    FONT_SIZE_PHONE,
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';

import React from 'react';
import styled from 'styled-components';

interface HeaderButtonStyledProps {
    isSelected: boolean;
}
/**
 * @description header button style CSS
 */
const HeaderButtonStyled = styled.button<HeaderButtonStyledProps>`
    align-items: center;
    background: ${(props) => (props.isSelected ? MAIN_THEME : LIGHT_GRAY)};
    border: solid;
    border-color: ${(props) => (props.isSelected ? LIGHT_GRAY : MEDIUM_GRAY)};
    border-radius: 5px 5px 0 0;
    border-width: 1.5px 1.5px 0 1.5px;
    color: ${(props) => (props.isSelected ? WHITE : MAIN_THEME)};
    display: flex;
    font-size: ${FONT_SIZE_DESKTOP}em;
    font-weight: 500;
    margin: ${(props) => (props.isSelected ? '0.2' : '0.5')}em 0.25em 0 0.25em;
    padding: 0em 2em 0em 2em;

    &:hover {
        background: ${MEDIUM_GRAY};
        border-color: ${DARK_GRAY};
        color: ${MAIN_THEME};
        margin: 0.2em 0.25em 0 0.25em;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        padding: 0em 0.25em 0em 0.25em;
        font-size: ${FONT_SIZE_PHONE}em;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        padding: 0em 2em 0em 2em;
        font-size: ${FONT_SIZE_PHONE}em;
    }
`;
/**
 * @description props for the header button component
 * @interface Props
 */
interface Props {
    /**
     * @description text to display in the button
     * @type {string}
     */
    text: string;
    /**
     * @description function to run on click the button
     */
    onClick: () => void;
    /**
     * @description is button selected
     * @type {boolean}
     */
    isSelected: boolean;
}
/**
 * @description header button functional component
 * @type {React.FC}
 */
const HeaderButton: React.FC<Props> = ({ text, onClick, isSelected }) => {
    return (
        <HeaderButtonStyled isSelected={isSelected} onClick={() => onClick()}>
            <span>{text}</span>
        </HeaderButtonStyled>
    );
};

export default HeaderButton;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Type = exports.Provider = exports.Status = void 0;
/**
 * @description enumeration of provider used in nornet
 */
var Provider;
(function (Provider) {
    Provider["Telenor"] = "Telenor";
    Provider["Telia"] = "Telia";
    Provider["ICENET"] = "ice.net";
    Provider["ICENETR"] = "ice.net R";
    Provider["OneCall"] = "One Call";
    Provider["LAN"] = "LAN";
    Provider["NetworkNorway"] = "Network Norway";
})(Provider || (exports.Provider = Provider = {}));
/**
 * @description enumeration of network status
 */
var Status;
(function (Status) {
    Status["UNKNOWN"] = "UNKNOWN";
    Status["NONE"] = "NONE";
    Status["LTE"] = "LTE";
    Status["UNDEFINED"] = "UNDEFINED";
    Status["UNAVAILABLE"] = "UNAVAILABLE";
})(Status || (exports.Status = Status = {}));
/**
 * @description enumeration of network type
 */
var Type;
(function (Type) {
    Type["_3GPP"] = "3GPP";
    Type["_802DOT3"] = "802.3";
})(Type || (exports.Type = Type = {}));

import React from 'react';

/**
 * @description Table footer functional component
 * @type {React.FC}
 */
const TableFooter: React.FC = () => {
    return <tfoot></tfoot>;
};

export default TableFooter;

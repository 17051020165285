import { BLACK, BORDER, SUBTHEME } from 'src/constants/colors';

import styled from 'styled-components';

/**
 * @description grid container props
 * @interface GridContainerProps
 */
interface GridContainerProps {
    /**
     * @description grid area CSS
     * @type {string}
     */
    grid_area: string;
}
/**
 * @description grid container style CSS
 */
const GridContainer = styled.div<GridContainerProps>`
    grid-area: ${(props) => (props.grid_area ? props.grid_area : 'auto')};
    display: flex;
    flex: 1;
    padding: 0.5em 0em 0.5em 0em;
`;
/**
 * @description grid sub container style CSS
 */
const GridSubContainer = styled.div`
    background: ${SUBTHEME};
    border-color: ${BORDER};
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    color: ${BLACK};
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1em 2em 1em 1em;
`;

export { GridContainer, GridSubContainer };

import { BLACK, BORDER, HOVER } from 'src/constants/colors';

import { MAX_WIDTH_PHONE_PORTRAIT } from 'src/constants/config';
import React from 'react';
import RefreshButton from 'src/components/buttons/RefreshButton';
import styled from 'styled-components';

/**
 * @description button styled props
 * @interface ButtonStyledProps
 */
interface ButtonStyledProps {
    /**
     * @description background color
     * @type {string}
     */
    background: string;
    /**
     * @description border width
     * @type {string}
     */
    borderWidth: string;
    /**
     * @description display refresh
     * @type {boolean}
     */
    displayRefresh: boolean;
}
/**
 * @description button style CSS
 */
const ButtonStyled = styled.div<ButtonStyledProps>`
    align-items: center;
    align-self: self-end;
    background: ${(props) => (props.background ? props.background : BLACK)};
    border: solid;
    border-color: ${BORDER};
    border-radius: 5px 5px 0 0;
    border-width: ${(props) =>
        props.borderWidth ? props.borderWidth : '0px 0px 0px 0px'};
    color: ${BLACK};
    cursor: default;
    display: flex;
    font-size: 1em;
    margin: 0em 0.5em;
    padding: 0.5em 1em;

    &:hover {
        color: ${(props) => (props.displayRefresh ? BLACK : HOVER)};
    }

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        padding: 0em 0em 0em 0em;
    }
`;
/**
 * @description container style CSS
 */
const Container = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
`;
/**
 * @description span style CSS
 */
const Span = styled.span`
    margin: 0em 2em 0em 2em;
`;
/**
 * @description refresh button container style CSS
 */
const RefreshButtonContainer = styled.div``;
/**
 * @description props for the tab button component
 * @interface Props
 */
interface Props {
    /**
     * @description background color
     * @type {string}
     */
    background: string;
    /**
     * @description border width
     * @type {string}
     */
    borderWidth: string;
    /**
     * @description display refresh in the tab button
     * @type {boolean}
     */
    displayRefresh: boolean;
    /**
     * @description text of the tab button
     * @type {string}
     */
    text: string;
    /**
     * @function onClick
     * @description handle on click on tab button
     * @returns {void}
     */
    onClick: () => void;
    /**
     * @function onRefresh
     * @description handle on refresh on tab button
     * @returns {void}
     */
    onRefresh: () => void;
}
/**
 * @description tab button functional component
 * @type {React.FC}
 */
const TabButton: React.FC<Props> = ({
    background,
    borderWidth,
    displayRefresh,
    text,
    onClick,
    onRefresh,
}: Props) => {
    return displayRefresh ? (
        <ButtonStyled
            onClick={onClick}
            borderWidth={borderWidth}
            background={background}
            displayRefresh={true}
        >
            <Container>
                <RefreshButtonContainer>
                    <RefreshButton onClick={onRefresh} />
                </RefreshButtonContainer>
                <Span>{text}</Span>
            </Container>
        </ButtonStyled>
    ) : (
        <ButtonStyled
            onClick={onClick}
            borderWidth={borderWidth}
            background={background}
            displayRefresh={false}
        >
            <Container>
                <Span>{text}</Span>
            </Container>
        </ButtonStyled>
    );
};

export default TabButton;

import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

const useAuthorization = (): [boolean] => {
    const [cookies] = useCookies(['Authorization']);

    let authorization: string | undefined = cookies.Authorization;

    useEffect(() => {
        let isUnmounted = false;

        if (isUnmounted) authorization = cookies.Authorization;

        return () => {
            isUnmounted = true;
        };
    }, [cookies]);

    return [authorization !== undefined];
};

export { useAuthorization };

import Circle from 'src/img/Circle';
import React from 'react';
import { Status } from 'src/constants/colors';
import styled from 'styled-components';
import { translateStatus } from 'src/utils/translate';

/**
 * @description container props
 * @interface ContainerProps
 */
interface ContainerProps {
    /**
     * @description grid column
     * @type {string}
     */
    gridColumn: string;
    /**
     * @description grid row
     * @type {string}
     */
    gridRow: string;
}
/**
 * @description container style CSS
 */
const Container = styled.div<ContainerProps>`
    align-self: center;
    display: flex;
    grid-column: ${(props) => (props.gridColumn ? props.gridColumn : 'auto')};
    grid-row: ${(props) => (props.gridRow ? props.gridRow : 'auto')};
    justify-self: baseline;
`;
/**
 * @description span style CSS
 */
const Span = styled.span`
    font-size: 0.9em;
    margin: 0 0 0 0.3em;
`;
/**
 * @description props for caption itme
 * @interface Props
 */
interface Props {
    /**
     * @description status of the caption item
     * @type {string}
     */
    status: string;
    /**
     * @description the column of the caption
     * @type {string}
     */
    gridColumn: string;
    /**
     * @description the row of the caption
     * @type {string}
     */
    gridRow: string;
}
/**
 * @description caption item functional component
 * @type {React.FC}
 */
const CaptionItem: React.FC<Props> = ({
    status,
    gridColumn,
    gridRow,
}: Props) => {
    return (
        <Container gridColumn={gridColumn} gridRow={gridRow}>
            <Circle size={10} fill={Status[status as keyof typeof Status]} />
            <Span>{translateStatus(status)}</Span>
        </Container>
    );
};

export default CaptionItem;

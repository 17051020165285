import {
    BORDER,
    DARK_GRAY,
    GRAPHIC_TEXT,
    WHITE,
    provider,
} from 'src/constants/colors';
import {
    Brush,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import {
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { Measurement, Provider } from '@nne-viz/common';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import SquareLoader, {
    SquareLoaderContainer,
} from 'src/components/loader/SquareLoader';

import NodeGraphData from 'src/models/NodeGraphData';
import { NorwayDayjs } from 'src/utils/dates';
import React from 'react';
import { measureUnit } from 'src/utils/math';
import styled from 'styled-components';
import { translateMeasurement } from 'src/utils/translate';
import { translateProvider } from 'src/utils/translate';

/**
 * @description chart container style CSS
 */
const ChartContainer = styled.div`
    align-items: center;
    color: ${GRAPHIC_TEXT};
    display: flex;
    flex: 2;
    flex-direction: column;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        min-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        min-height: 85vh;
    }
`;
/**
 * @description empty chart container style CSS
 */
const EmptyChartContainer = styled.div`
    align-items: center;
    background: ${BORDER};
    display: flex;
    justify-content: center;
    margin: 10px 75px 15px 10px;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        min-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        min-height: 85vh;
    }
`;
const EmptySpan = styled.span`
    background: ${WHITE};
    padding: 1em 1em;
`;
/**
 * @description square loader cont`iner style CSS
 */
const SquareLoaderContainerStyled = styled(SquareLoaderContainer)`
    display: flex;
    flex: 2;
`;
/**
 * @description tool tip container style CSS
 */
const TooltipContainer = styled.div`
    background: ${WHITE};
    border: solid;
    border-radius: 5px 5px 5px 5px;
    border-width: 1px;
    padding: 0.2em 0.5em 0.2em 0.5em;
`;

/**
 * @description Props of the node graph component
 * @interface Props
 */
interface Props {
    /**
     * @description date for displaying data
     * @type {NorwayDayjs}
     */
    datetime: NorwayDayjs;
    /**
     * @description the data to display
     * @type {NodeGraphData[]}
     */
    graphicData: NodeGraphData[];
    /**
     * @description is the graphic loading
     * @type {boolean}
     */
    isGraphicLoading: boolean;
    /**
     * @description keys of the graphic
     * @type {string[]}
     */
    graphicKeys: string[];
    /**
     * @description value to display
     * @type {Measurement}
     */
    value: Measurement;
}

const NodeGraph: React.FC<Props> = ({
    datetime,
    graphicData,
    isGraphicLoading,
    graphicKeys,
    value,
}: Props) => {
    /**
     * @description the custom tool tip display when hover a data of the graphic
     * @type {React.FC}
     */
    const CustomTooltip: React.FC = ({
        active,
        payload,
    }: TooltipProps<ValueType, NameType>) => {
        if (active && payload && payload.length) {
            // Retrieve the time
            const time = payload.filter((p) => p.name === 'time')[0].value;
            // Retrieve the data payload
            const dataPayload = payload.filter((p) => p.name !== 'time')[0];
            // Retrieve the network
            const network = dataPayload.dataKey;
            // Retrieve the value of the data
            const data: string = (dataPayload.value as number).toString();
            return (
                <TooltipContainer>
                    <p>Time: {time}</p>
                    <p>
                        {translateMeasurement(value)}: {data.substring(0, 6)}
                        {measureUnit(value)}
                    </p>
                    <p>Network: {network}</p>
                </TooltipContainer>
            );
        }

        return null;
    };
    /**
     * @function renderGraphicLines
     * @description render the lines for the graphic
     * @param {string[]} keys lines to render
     * @returns {JSX.Element}
     */
    const renderGraphicLines = (keys: string[]): JSX.Element => {
        const renders = keys.map((isp) => (
            <Scatter
                key={isp}
                dataKey={isp}
                fill={provider(isp as Provider)}
                name={value}
            />
        ));
        return <>{renders}</>;
    };
    // format data according to data measure unit
    const formatData: NodeGraphData[] = graphicData.map(
        (data: NodeGraphData) => {
            // copy the data
            const xData: NodeGraphData = { ...data };
            // for each network value
            Object.keys(data).forEach((key) => {
                if (key !== 'iso8601' && key !== 'time') {
                    switch (value) {
                        case Measurement.LATENCY:
                            xData[key] = (data[key] as number) * 1000;
                            break;
                        default:
                            break;
                    }
                }
            });
            return xData;
        }
    );

    /**
     * @function renderGraphic
     * @description render the graphic according to loading or not
     * @returns {JSX.Element}
     */
    const renderGraphic = (): JSX.Element => {
        return isGraphicLoading ? (
            <SquareLoaderContainerStyled>
                <SquareLoader />
            </SquareLoaderContainerStyled>
        ) : formatData.length > 0 ? (
            <ChartContainer>
                <span>{datetime.format('DD/MM/YYYY')}</span>
                <ResponsiveContainer minWidth={100} minHeight={100} height={'95%'}>
                    <ScatterChart
                        data={formatData}
                        margin={{ top: 10, right: 75, left: 15, bottom: 10 }}
                    >
                        <Legend
                            align="center"
                            formatter={(value, entry) => {
                                const { dataKey } = entry as unknown as {
                                    dataKey: Provider;
                                };
                                return (
                                    <span>{translateProvider(dataKey)}</span>
                                );
                            }}
                            verticalAlign="bottom"
                        />
                        <Brush
                            dataKey="time"
                            height={20}
                            stroke={DARK_GRAY}
                            alwaysShowText={true}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<CustomTooltip />} />
                        <YAxis type="number" unit={measureUnit(value)}></YAxis>
                        <XAxis dataKey={'time'} angle={0} />
                        {renderGraphicLines(graphicKeys)}
                    </ScatterChart>
                </ResponsiveContainer>
            </ChartContainer>
        ) : (
            <EmptyChartContainer>
                <EmptySpan>No data available.</EmptySpan>
            </EmptyChartContainer>
        );
    };
    return renderGraphic();
};

export default NodeGraph;

import { MeasurementData } from '@nne-viz/common';
import { norwaydayjs } from 'src/utils/dates';

/**
 * @description Model for graphic data to display
 * @interface NodeGraphData
 */
interface NodeGraphData {
    /**
     * @description iso8601 date representation
     * @type {string}
     */
    iso8601: string;
    /**
     * @description date
     * @type {string}
     */
    time: string;
    /**
     * @description string key can be number (or string for date)
     */
    [key: string]: number | string;
}

/**
 * @function measurementToNodeGraph
 * @description Takes an array of NodeData objects and returns an array of GraphicData objects.
 * Tricky function that change the format of the data
 * To fully understand this function, it is a must to look at the format at the data retrieve from the API
 * And the format of the data of recharts
 * @param {MeasurementData[]} data - An array of NodeData objects to be formatted as GraphicData objects.
 * @returns {NodeGraphData[]} An array of GraphicData objects, where each object has a datetime and a value corresponding to a unique network name.
 */
const measurementToNodeGraph = (data: MeasurementData[]): NodeGraphData[] => {
    // Init res array of data for graphic
    // Each object is defined by a datetime and the value from different network
    const res: NodeGraphData[] = [];
    // For each measurements
    data.forEach((measurement: MeasurementData) => {
        // Check if there is exisiting object with datetime
        const existing_datetime: NodeGraphData | undefined = res.find(
            (element) => element.iso8601 === measurement.iso8601
        );
        // If there is existing datetime
        if (
            existing_datetime !== undefined &&
            measurement.value !== undefined
        ) {
            // Add to this object the data from this measurement
            existing_datetime[measurement.provider as keyof NodeGraphData] =
                measurement.value;
            // Else
        } else {
            // Create a new object with the datetime
            const aux: NodeGraphData = {
                iso8601: measurement.iso8601,
                time: norwaydayjs(measurement.iso8601).format('HH:mm:ss'),
            };
            if (measurement.value !== undefined)
                // Add to this data the data from this measurement
                aux[measurement.provider as keyof NodeGraphData] =
                    measurement.value;
            // Add the object
            res.push(aux);
        }
    });
    return res;
};

export default NodeGraphData;

export { measurementToNodeGraph };

import { useEffect, useReducer, useState } from 'react';

import { REACT_APP_API_URL } from 'src/constants/config';
import { Response } from '@nne-viz/common';
import { Toast } from 'src/components/Toastr';
import { useAuthorization } from 'src/services/auth';

interface useApiState<T> {
    data: T;
    isError: boolean;
    isLoading: boolean;
}

enum ActionType {
    FETCH_INIT = 'FETCH_INIT',
    FETCH_SUCCESS = 'FETCH_SUCCES',
    FETCH_ERROR = 'FETCH_ERROR',
}

interface useApiAction<T> {
    payload?: T;
    type: ActionType;
}

const useApiReducer = <T,>(
    state: useApiState<T>,
    action: useApiAction<T>
): useApiState<T> => {
    switch (action.type) {
        case ActionType.FETCH_INIT:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case ActionType.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload ? action.payload : state.data,
            };
        case ActionType.FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
    }
};

enum Method {
    POST = 'POST',
    GET = 'GET',
}

interface useApiDoFetch {
    endpoint?: string;
    body?: Record<string, unknown>;
}

interface _useApiDoFetch extends useApiDoFetch {
    _switch: boolean;
}

interface useApiProps<T> {
    method: Method;
    isAuthorizationNeeded: boolean;
    initialEndpoint?: string;
    initialBody?: Record<string, unknown>;
    header?: Record<string, string>;
    initialData: T;
}

const useApi = <T,>({
    method,
    isAuthorizationNeeded,
    initialEndpoint,
    initialBody,
    header,
    initialData,
}: useApiProps<T>): [useApiState<T>, (state: useApiDoFetch) => void] => {
    const [isAuthorized] = useAuthorization();

    const [{ endpoint, body, _switch }, setState] = useState<_useApiDoFetch>({
        endpoint: initialEndpoint,
        body: initialBody,
        _switch: false,
    });

    const [state, dispatch] = useReducer<
        React.Reducer<useApiState<T>, useApiAction<T>>
    >(useApiReducer, {
        isLoading: false,
        isError: false,
        data: initialData,
    });

    const _body = JSON.stringify(body);

    useEffect(() => {
        let isUnmounted = false;

        const fetchApi = async () => {
            try {
                dispatch({
                    type: ActionType.FETCH_INIT,
                });

                const response = await fetch(
                    `${REACT_APP_API_URL}${endpoint}`,
                    {
                        method: method,
                        credentials: isAuthorizationNeeded ? 'include' : 'omit',
                        headers: {
                            'Content-Type': 'application/json',
                            ...header,
                        },
                        body: _body,
                    }
                );

                const res = (await response.json()) as Response<T>;
                if (!isUnmounted) {
                    if (res.error) {
                        dispatch({
                            type: ActionType.FETCH_ERROR,
                        });
                        Toast.error(
                            `Error ${res.error.code}: ${res.error.message}`
                        );
                    }
                    if (res.data)
                        dispatch({
                            type: ActionType.FETCH_SUCCESS,
                            payload: res.data,
                        });
                }
            } catch (error) {
                console.error(error);
                if (!isUnmounted) {
                    dispatch({
                        type: ActionType.FETCH_ERROR,
                    });
                    Toast.error('Error 503: Service unavailable');
                }
            }
        };

        if (
            endpoint !== undefined &&
            (!isAuthorizationNeeded || (isAuthorizationNeeded && isAuthorized))
        ) {
            fetchApi();
        }

        return () => {
            isUnmounted = true;
        };
    }, [_switch, isAuthorized]);

    const doFetch = ({ endpoint, body }: useApiDoFetch) => {
        setState(({ _switch: _previousSwitch }: _useApiDoFetch) => ({
            endpoint: endpoint,
            body: body,
            _switch: !_previousSwitch,
        }));
    };

    return [state, doFetch];
};

export { useApi, Method };

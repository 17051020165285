"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
var Status;
(function (Status) {
    Status["ALL"] = "ALL";
    Status["SOME"] = "SOME";
    Status["UNK"] = "UNK";
    Status["NONE"] = "NONE";
    Status["LTE"] = "LTE";
    Status["_3G"] = "3G";
    Status["_2G"] = "2G";
    Status["UNA"] = "UNA";
    Status["UNDEFINED"] = "UNDEFINED";
})(Status || (exports.Status = Status = {}));

import CycleLoader, {
    CycleLoaderContainer,
} from 'src/components/loader/CycleLoader';
import { HeatMapDatum, ResponsiveHeatMap } from '@nivo/heatmap';
import { IInterface, Measurement, MeasurementData } from '@nne-viz/common';
import IInterfaceGraphData, {
    IInterfaceHeatMapData,
    measurementToIInterfaceGraph,
} from 'src/models/IInterfaceGraphData';
import { Method, useApi } from 'src/services/api';
import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';
import React, { useEffect, useState } from 'react';

import { AxisProps } from '@nivo/axes';
import { BORDER } from 'src/constants/colors';
import NodeModal from '../node/NodeModal';
import styled from 'styled-components';

const PlotContainer = styled.div`
    align-items: center;
    width: 99%;
    height: 95%;
`;

interface Props {
    from: NorwayDayjs;
    iinterfaces: IInterface[];
    measurement: Measurement;
    to: NorwayDayjs;
}

const IInterfaceGraph: React.FC<Props> = ({
    from,
    to,
    iinterfaces,
    measurement,
}) => {
    const [data, setData] = useState<IInterfaceGraphData[]>([]);
    const [{ data: fetchData, isLoading }, doFetch] = useApi<MeasurementData[]>(
        {
            method: Method.POST,
            isAuthorizationNeeded: true,
            initialData: [],
            initialEndpoint: `interface/measurement/${measurement}`,
            initialBody: {
                interfaces: iinterfaces.map((iinterface) => ({
                    node_id: iinterface.nodeId,
                    network_id: iinterface.networkId,
                })),
                from: from.format(),
                to: to.format(),
            },
        }
    );
    const [nodeModalData, setNodeModalData] = useState<
        IInterfaceHeatMapData | undefined
    >(undefined);

    useEffect(() => {
        doFetch({
            endpoint: `interface/measurement/${measurement}`,
            body: {
                interfaces: iinterfaces.map((iinterface) => ({
                    node_id: iinterface.nodeId,
                    network_id: iinterface.networkId,
                })),
                from: from.format(),
                to: to.format(),
            },
        });
    }, [from, to, iinterfaces, measurement]);

    useEffect(() => {
        setData(measurementToIInterfaceGraph(fetchData, iinterfaces, from, to));
    }, [fetchData]);

    const getValueFormat = (measurement: Measurement): string => {
        switch (measurement) {
            case Measurement.LATENCY:
                return ' ^-0,.0~f';
            default:
                return ' ^-0,.2~f';
        }
    };

    const getValueFormatLegend = (measurement: Measurement): string => {
        switch (measurement) {
            case Measurement.LATENCY:
                return '>-,.0~f';
            default:
                return '>-,.0~f';
        }
    };

    const getMaxValue = (measurement: Measurement): number => {
        switch (measurement) {
            case Measurement.LATENCY:
                return 500;
            case Measurement.PACKET_LOSS:
                return 100;
            default:
                return 100;
        }
    };

    const getDivergeAt = (measurement: Measurement): number => {
        switch (measurement) {
            case Measurement.LATENCY:
                return 0.3;
            case Measurement.PACKET_LOSS:
                return 0.15;
            default:
                return 0.5;
        }
    };

    const formatData: IInterfaceGraphData[] = data.map(
        (value: IInterfaceGraphData) => {
            const formatValue = {
                ...value,
                data: value.data.map((measurementData) => {
                    switch (measurement) {
                        case Measurement.LATENCY:
                            return {
                                ...measurementData,
                                y:
                                    measurementData.y !== undefined
                                        ? measurementData.y * 1000
                                        : undefined,
                            };
                        default:
                            return measurementData;
                    }
                }),
            };
            return formatValue;
        }
    );

    /**
     * @function renderNodeModal
     * @description display for the modal of the node selected
     * @returns {JSX.Element | null}
     */
    const renderNodeModal = (): JSX.Element | null => {
        return nodeModalData ? (
            <NodeModal
                close={() => {
                    document.body.style.overflowY = 'auto';
                    setNodeModalData(undefined);
                }}
                getDatetime={() => norwaydayjs(nodeModalData.iso8601)}
                nodeId={nodeModalData.nodeId}
                measurement={measurement}
            />
        ) : null;
    };

    return isLoading ? (
        <CycleLoaderContainer>
            <CycleLoader />
        </CycleLoaderContainer>
    ) : (
        <PlotContainer>
            <ResponsiveHeatMap
                animate={true}
                axisBottom={null}
                axisLeft={
                    {
                        orient: 'left',
                        tickSize: 15,
                        tickPadding: 15,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 0,
                    } as AxisProps<HeatMapDatum>
                }
                axisRight={null}
                axisTop={
                    {
                        orient: 'top',
                        tickSize: 15,
                        tickPadding: 15,
                        tickRotation: -45,
                        legend: '',
                        legendOffset: 36,
                    } as AxisProps<HeatMapDatum>
                }
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                }}
                borderRadius={0}
                borderWidth={1}
                colors={{
                    type: 'diverging',
                    colors: ['#4DE37F', '#FFD580', '#ff2a26'],
                    divergeAt: getDivergeAt(measurement),
                    minValue: 0,
                    maxValue: getMaxValue(measurement),
                }}
                data={formatData}
                emptyColor={BORDER}
                enableGridX={true}
                enableGridY={true}
                hoverTarget="cell"
                inactiveOpacity={1}
                labelTextColor="BLACK"
                legends={[
                    {
                        anchor: 'top',
                        translateX: 0,
                        translateY: -120,
                        length: 800,
                        thickness: 20,
                        direction: 'row',
                        tickPosition: 'after',
                        tickSize: 7,
                        tickSpacing: 4,
                        tickOverlap: false,
                        tickFormat: getValueFormatLegend(measurement),
                        title: 'Value →',
                        titleAlign: 'start',
                        titleOffset: 4,
                    },
                ]}
                margin={{ top: 150, right: 10, bottom: 10, left: 100 }}
                onClick={(cell) => {
                    setNodeModalData(cell.data);
                }}
                valueFormat={getValueFormat(measurement)}
            />
            {renderNodeModal()}
        </PlotContainer>
    );
};

export default IInterfaceGraph;

import {
    Navigate,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import Comparative from 'src/scenes/comparative';
import Map from 'src/scenes/map';
import React from 'react';
import Statistics from 'src/scenes/statistics';
import { withCookies } from 'react-cookie';

/**
 * @description router for leading to component according to URL
 */
const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/map" />,
    },

    {
        path: '/comparative/*',
        element: <Comparative />,
    },

    {
        path: '/map/*',
        element: <Map />,
    },

    {
        path: '/statistics/*',
        element: <Statistics />,
    },
]);
/**
 * @description Web application functional component
 * @type {React.FC}
 */
const app: React.FC = () => {
    return <RouterProvider router={router} />;
};

export default withCookies(app);

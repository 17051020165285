import { MAIN_THEME, SUBTHEME } from 'src/constants/colors';

import { ColorRing } from 'react-loader-spinner';
import React from 'react';
import styled from 'styled-components';

/**
 * @description cycle loader container style CSS
 */
const CycleLoaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;
/**
 * @description cycle loader functional component
 * @type {React.FC}
 */
const CycleLoader: React.FC = () => {
    return (
        <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[SUBTHEME, SUBTHEME, MAIN_THEME, MAIN_THEME, SUBTHEME]}
        />
    );
};

export default CycleLoader;

export { CycleLoaderContainer };

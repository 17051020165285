import React from 'react';
import styled from 'styled-components';

/**
 * @description Props for table body
 * @interface Props
 */
interface Props {
    /**
     * @description children of table body to display
     * @type {React.ReactNode | undefined}
     */
    children?: React.ReactNode;
}

const TableBodyStyled = styled.tbody`
    display: grid;
    overflow-y: scroll;
`;
/**
 * @description Table body functional component
 * @type {React.FC}
 */
const TableBody: React.FC<Props> = ({ children }: Props) => {
    return <TableBodyStyled>{children}</TableBodyStyled>;
};

export default TableBody;

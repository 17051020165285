import { Measurement, Nullable } from '@nne-viz/common';
import dayjs, { Dayjs as NorwayDayjs } from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const norwaydayjs = (
    date?: string | number | Date | dayjs.Dayjs | null | undefined, format?: Nullable<string>
): NorwayDayjs => {
    if (format) return dayjs(date, format, true).tz('Europe/Oslo');
    return dayjs(date).tz('Europe/Oslo');
};



const norwaydayjsToUrlIso8601 = (datetime: NorwayDayjs) => {
    const iso8601 = datetime.format();
    return iso8601.replaceAll('+', '%2B').replaceAll('-', '%2D');
};

const roundTo5Minute = (
    datetime: NorwayDayjs,
    rounded: 'next' | 'previous'
) => {
    const remainder: number = datetime.minute() % 5;
    switch (rounded) {
        case 'next':
            return datetime.add(5 - remainder, 'minute');
        case 'previous':
            return datetime.subtract(remainder, 'minute');
    }
};

const retrieve5minuteInterval = (
    from: NorwayDayjs,
    to: NorwayDayjs
): NorwayDayjs[] => {
    const res: NorwayDayjs[] = [];
    let aux = from.clone();
    while (aux.isBetween(from, to, 'minute', '[]')) {
        res.push(aux);
        aux = aux.add(5, 'minute');
    }
    return res;
};

/**
 * @function retrieveDaysInterval
 * @description create list of every single day from date to date
 * @param {NorwayDayjs} from
 * @param {NorwayDayjs} to
 * @returns {string[]}
 */
const retrieveDaysInterval = (from: NorwayDayjs, to: NorwayDayjs): string[] => {
    const res: string[] = [];
    let aux = from.clone();
    while (aux.isBetween(from, to, 'day', '[]')) {
        res.push(aux.format('DD/MM/YYYY'));
        aux = aux.add(1, 'day');
    }
    return res;
};

/**
 * @function getMinutesAgo
 * @description According to the graphic display, we want to display data from different time ago
 * @param {Measurement} value the data that is displayed
 * @returns {number} the minutes ago that data should display
 */
const getMinutesRange = (value: Measurement): number => {
    switch (value) {
        case Measurement.LATENCY:
            return 10;
        case Measurement.PACKET_LOSS:
            return 180;
        default:
            return 0;
    }
};

export {
    norwaydayjs,
    NorwayDayjs,
    norwaydayjsToUrlIso8601,
    retrieveDaysInterval,
    roundTo5Minute,
    retrieve5minuteInterval,
    getMinutesRange,
};

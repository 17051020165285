import { BORDER, Status, WHITE, provider } from 'src/constants/colors';

import Circle from 'src/img/Circle';
import { Network } from '@nne-viz/common';
import React from 'react';
import styled from 'styled-components';
import { translateProvider } from 'src/utils/translate';

/**
 * @description table rows props
 * @interface TableRowsProps
 */
interface TableRowProps {
    /**
     * @description the length of the column
     * @type {number}
     */
    columnLength: number;
    /**
     * @description is last index of the table
     * @type {boolean}
     */
    isLastIndex: boolean;
}
/**
 * @description table row style CSS
 */
const TableRow = styled.tr<TableRowProps>`
    background: ${WHITE};
    border: solid;
    border-color: ${BORDER};
    border-width: 0 0 ${(props) => (props.isLastIndex ? '0' : '1')}px 0;
    display: grid;
    grid-template-columns: repeat(
        ${(props) => (props.columnLength ? props.columnLength : '1')},
        1fr
    );
    &:hover {
        background: ${BORDER};
    }
`;
/**
 * @description table data cell props
 * @interface TableDataCellProps
 */
interface TableDataCellProps {
    /**
     * @description the grid column of the cell related to CSS
     * @type {string}
     */
    grid_column: string;
}
/**
 * @description table data cell style CSS
 */
const TableDataCell = styled.td<TableDataCellProps>`
    grid-row: 1 / 1;
    grid-column: ${(props) =>
        props.grid_column ? props.grid_column : '1 / 1'};
    overflow: auto;
    padding: 1em;
`;
/**
 * @description props of the network item functional component
 * @interface Props
 */
interface Props {
    /**
     * @description is last index of the table
     * @type {boolean}
     */
    isLastIndex: boolean;
    /**
     * @description network
     * @type {Network}
     */
    network: Network;
    /**
     * @function onClick
     * @description function when clicking on the item
     * @param {string} value
     * @returns {void}
     */
    onClick: (value: string) => void;
}
/**
 * @description network item component tot didsplay in a list of networks
 * @type {React.FC}
 */
const NetworkItem: React.FC<Props> = ({
    network,
    isLastIndex,
    onClick,
}: Props) => {
    return (
        <TableRow
            columnLength={5}
            isLastIndex={isLastIndex}
            onClick={() => onClick(network.provider)}
        >
            <TableDataCell grid_column="1 / 2">
                <Circle fill={provider(network.provider)} size={10} />
            </TableDataCell>
            <TableDataCell grid_column="2 / 3">{network.id}</TableDataCell>
            <TableDataCell grid_column="3 / 4">
                {translateProvider(network.provider)}
            </TableDataCell>
            <TableDataCell grid_column="4 / 5">
                {network.signalStrength || 0} dB
            </TableDataCell>
            <TableDataCell grid_column="5 / 6">
                <Circle
                    fill={Status[network.status as keyof typeof Status]}
                    size={10}
                />
            </TableDataCell>
        </TableRow>
    );
};

export default NetworkItem;

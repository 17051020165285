import { Provider } from '@nne-viz/common';

/**
 * @description The main color theme.
 * @type {string}
 */
const MAIN_THEME = '#f15d22';
/**
 * @description The secondary color theme.
 * @type {string}
 */
const SUBTHEME = '#ebecee';
/**
 * @description The white color.
 * @type {string}
 */
const WHITE = '#ffffff';
/**
 * @description Light gray color
 * @type {string}
 */
const LIGHT_GRAY = '#f3f4f6';
/**
 * @description Medium gray color
 * @type {string}
 */
const MEDIUM_GRAY = '#cccccc';
/**
 * @description Dark gray color
 * @type {string}
 */
const DARK_GRAY = '#969696';
/**
 * @description The text hover color.
 * @type {string}
 */
const TEXT_HOVER = '#3366cc';
/**
 * @description The border color.
 * @type {string}
 */
const BORDER = '#e6e6e6';
/**
 * @description The hover color.
 * @type {string}
 */
const HOVER = '#aad3df';
/**
 * @description The node hover color.
 * @type {string}
 */
const NODE_HOVER = '#c3e6ff';
/**
 * @description The black color.
 * @type {string}
 */
const BLACK = '#000000';
/**
 * @description Color of the text in graphics
 * @type {string}
 */
const GRAPHIC_TEXT = '#808080';
const FOCUS = '#1976d2';

const provider = (provider: Provider): string => {
    switch (provider) {
        case Provider.Telenor:
            return '#19aaf8';
        case Provider.Telia:
            return '#9308db';
        case Provider.ICENET:
            return '#ffb900';
        case Provider.ICENETR:
            return '#ff8800';
        case Provider.LAN:
            return '#00cc5c';
        case Provider.NetworkNorway:
            return '#757575';
        case Provider.OneCall:
            return '#757575';
    }
};

/**
 * @description An enumeration of status types and their associated colors.
 * @type enum
 * @typedef Status
 */
enum Status {
    /**
     * @description all network available
     * @type {string}
     */
    ALL = '#007850',
    /**
     * @description some network available
     * @type {string}
     */
    SOME = '#efc94d',
    /**
     * @description unknown network state
     * @type {string}
     */
    UNK = '#dddddd',
    /**
     * @description none network available
     * @type {string}
     */
    NONE = '#e13737',
    /**
     * @description lte network state
     * @type {string}
     */
    LTE = '#007850',
    /**
     * @description unknown network state
     * @type {string}
     */
    UNKNOWN = '#dddddd',
    /**
     * @description unavailable network state
     * @type {string}
     */
    UNAVAILABLE = '#ff2929',
}
/**
 * @description An enumeration of upload speed rate types and their associated colors.
 * @type enum
 * @typdef UpRate
 */
enum UpRate {
    /**
     * @description very high rate
     * @type {string}
     */
    VERY_HIGH = '#007850',
    /**
     * @description high rate
     * @type {string}
     */
    HIGH = '#329696',
    /**
     * @description medium rate
     * @type {string}
     */
    MEDIUM = '#efc94d',
    /**
     * @description low rate
     * @type {string}
     */
    LOW = '#e16437',
    /**
     * @description very low rate
     * @type {string}
     */
    VERY_LOW = '#e13737',
    /**
     * @description default
     * @type {string}
     */
    DEFAULT = '#ffffff',
}

export {
    MAIN_THEME,
    SUBTHEME,
    WHITE,
    TEXT_HOVER,
    BORDER,
    NODE_HOVER,
    FOCUS,
    BLACK,
    HOVER,
    GRAPHIC_TEXT,
    LIGHT_GRAY,
    MEDIUM_GRAY,
    DARK_GRAY,
    Status,
    UpRate,
    provider,
};

import React from 'react';
import styled from 'styled-components';

/**
 * @description props of the circle
 * @interface Props
 */
interface Props {
    /**
     * @description the color to fill the circle with
     * @type {string}
     */
    fill: string;
    /**
     * @description the size of the circle
     * @type {number}
     */
    size: number;
}

/**
 * @description SVG styled CSS
 */
const SVGStyled = styled.svg``;

/**
 *	@description Circle SVG image
 * @param {Props} {fill, size}
 * @type {React.FC<Props>}
 */
const Circle: React.FC<Props> = ({ fill, size }: Props) => {
    return (
        <SVGStyled
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            width={size * 2}
            height={size * 2}
            viewBox={`0 0 ${size * 2} ${size * 2}`}
        >
            <circle cx={size} cy={size} r={size} />
        </SVGStyled>
    );
};

export default Circle;

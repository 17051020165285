import 'src/components/datetime/FromToTimeSlider.css';

import React from 'react';
import Slider from 'react-slider';
import styled from 'styled-components';

const STEP = 5;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Header = styled.div`
    margin-bottom: 10px;
`;

interface FromToTimeProps {
    fromHour: number;
    fromMinute: number;
    gap: number;
    onChange: (fromHour: number, fromMinute: number) => void;
}

const FromToTime: React.FC<FromToTimeProps> = ({
    fromHour,
    fromMinute,
    gap,
    onChange,
}) => {
    const handleRangeChange = (value: number) => {
        const fromHour = Math.trunc(value / 60);
        const fromMinute = value - fromHour * 60;
        onChange(fromHour, fromMinute);
    };

    const formatTime = () => {
        return `${fromHour.toString().padStart(2, '0')}:${fromMinute
            .toString()
            .padStart(2, '0')} - ${(fromHour + gap)
            .toString()
            .padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}`;
    };

    return (
        <Container>
            <Header>
                <span>Select a time range</span>
            </Header>
            <Slider
                min={STEP}
                max={1254}
                step={STEP}
                value={fromHour * 60 + fromMinute}
                onChange={handleRangeChange}
                className="slider"
                renderThumb={(props) => {
                    const zIndexedProps = {
                        ...props,
                        style: {
                            // eslint-disable-next-line react/prop-types
                            ...props.style,
                            zIndex: 0,
                        },
                    };
                    return (
                        <div {...zIndexedProps} className="thumb">
                            {formatTime()}
                        </div>
                    );
                }}
                pearling
            />
        </Container>
    );
};

export default FromToTime;

export { STEP };

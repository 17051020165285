import { MeasurementData, Provider } from '@nne-viz/common';

import { norwaydayjs } from 'src/utils/dates';

/**
 * @description Model for graphic data to display
 * @interface NetworkGraphSwarmData
 */
interface NetworkGraphSwarmData {
    /**
     * @description date
     * @type {string}
     */
    date: string;
    /**
     * @description value of the data
     * @type {number}
     */
    value: number;
    /**
     * @description id of the specific data to display circles
     * @type {string}
     */
    id: string;
    /**
     * @description node id
     * @type {number}
     */
    nodeId: number;
    /**
     * @description provider
     * @type {Provider}
     */
    provider: Provider;
}

/**
 * @function measurementToNetworkSwarmGraph
 * @description convert measurement data to network swarm graphic data
 * @param {MeasurementData[]} data
 * @returns {NetworkGraphData[]}
 */
const measurementToNetworkSwarmGraph = (
    data: MeasurementData[]
): NetworkGraphSwarmData[] => {
    // init empty array
    const res: NetworkGraphSwarmData[] = [];
    // for each measurement
    data.forEach((measurement: MeasurementData) => {
        // Create a new object with the datetime
        const date = norwaydayjs(measurement.iso8601).format('DD/MM/YYYY');
        // Add the object
        if (measurement.value !== undefined)
            res.push({
                date: date,
                value: measurement.value,
                id: `${measurement.nodeId}${measurement.networkId}${date}`,
                nodeId: measurement.nodeId,
                provider: measurement.provider,
            });
    });
    return res;
};

export default NetworkGraphSwarmData;

export { measurementToNetworkSwarmGraph };

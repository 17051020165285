import React from 'react';

/**
 * @description props Sort
 * @interface Props
 */
interface Props {
    /**
     * @description color to fill the sort with
     * @type {string}
     */
    fill: string;
}
/**
 * @description Sort functional component
 * @param {Props} {fill}
 * @type {React.FC}
 */
const Sort: React.FC<Props> = ({ fill }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            height="9"
            transform="scale(1.3)"
            width="8"
        >
            <path d="m0,4 4-4 4,4zm0,1 4,4 4-4z" />
        </svg>
    );
};

export default Sort;

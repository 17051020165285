import { MeasurementData } from '@nne-viz/common';
import { norwaydayjs } from 'src/utils/dates';
import { provider } from 'src/constants/colors';
import { translateProvider } from 'src/utils/translate';

/**
 * @description Model for graphic data to display
 * @interface NetworkGraphLineData
 */
interface NetworkGraphLineData {
    /**
     * @description id i.e. provider
     * @type {string}
     */
    id: string;
    /**
     * @description color of the provider
     * @type {string}
     */
    color: string;
    /**
     * @description data for the corresponding provider
     * @type {string}
     */
    data: { x: string; y: number }[];
}

/**
 * @function measurementToNetworkLineGraph
 * @description convert measurement data to network line graphic data
 * @param {MeasurementData[]} data
 * @returns {NetworkGraphLineData[]}
 */
const measurementToNetworkLineGraph = (
    data: MeasurementData[]
): NetworkGraphLineData[] => {
    // init empty array
    const res: NetworkGraphLineData[] = [];
    // for each measurement
    data.forEach((measurement: MeasurementData) => {
        // Create a new object with the datetime
        const date = norwaydayjs(measurement.iso8601).format('DD/MM/YYYY');
        // look if existing provider in data
        const existingData: NetworkGraphLineData | undefined = res.find(
            (data) => data.id === translateProvider(measurement.provider)
        );
        // if provider existing
        if (existingData !== undefined) {
            // add data to provider
            existingData.data.push({
                x: date,
                y: measurement.value as number,
            });
            // else
        } else {
            // create new provider
            res.push({
                id: translateProvider(measurement.provider),
                color: provider(measurement.provider),
                data: [{ x: date, y: measurement.value as number }],
            });
        }
    });
    return res;
};

export default NetworkGraphLineData;

export { measurementToNetworkLineGraph };

import { IInterface, MeasurementData, Provider } from '@nne-viz/common';
import {
    NorwayDayjs,
    norwaydayjs,
    retrieve5minuteInterval,
    roundTo5Minute,
} from 'src/utils/dates';

import { translateProvider } from 'src/utils/translate';

export default interface IInterfaceGraphData {
    id: string;
    data: IInterfaceHeatMapData[];
    nodeId: number;
    provider: Provider;
    // eslint-disable-next-line semi
}

export interface IInterfaceHeatMapData {
    x: string;
    y: number | undefined;
    nodeId: number;
    iso8601: string;
}

/**
 * @function measurementToIInterfaceGraph
 * @description convert measurement data to network graphic data
 * @param {MeasurementData[]} data
 * @returns {IInterfaceGraphData[]}
 */
export const measurementToIInterfaceGraph = (
    data: MeasurementData[],
    iinterfaces: IInterface[],
    from: NorwayDayjs,
    to: NorwayDayjs
): IInterfaceGraphData[] => {
    const interval: NorwayDayjs[] = retrieve5minuteInterval(
        roundTo5Minute(from, 'next'),
        roundTo5Minute(to, 'previous')
    );
    // init empty array
    const res: IInterfaceGraphData[] = iinterfaces.map((iinterface) => ({
        id: `${iinterface.nodeId} ${translateProvider(iinterface.provider)}`,
        nodeId: iinterface.nodeId,
        provider: iinterface.provider,
        data: interval.map((datetime) => ({
            x: `${datetime.hour().toString().padStart(2, '0')}:${datetime
                .minute()
                .toString()
                .padStart(2, '0')}`,
            y: undefined,
            nodeId: iinterface.nodeId,
            iso8601: datetime.format(),
        })),
    }));
    // for each data
    data.forEach((item: MeasurementData) => {
        // create id from data
        const id = `${item.nodeId} ${translateProvider(item.provider)}`;
        const datetime: NorwayDayjs = norwaydayjs(item.iso8601);
        // retrieve existing id
        const existingId: IInterfaceGraphData | undefined = res.find(
            (graphData) => graphData.id === id
        );
        if (existingId) {
            // push new data to existing id
            const index: number = existingId.data.findIndex((xData) =>
                norwaydayjs(xData.iso8601).isSame(datetime, 'minute')
            );
            if (index !== -1) existingId.data[index].y = item.value;
        }
    });

    return res;
};

import { Method, useApi } from 'src/services/api';

import { Measurement } from '@nne-viz/common';
import Option from 'src/components/select/Option';
import React from 'react';
import Select from 'src/components/select/Select';
import styled from 'styled-components';
import { translateMeasurement } from 'src/utils/translate';

/**
 * @description select container style CSS
 */
const SelectContainer = styled.div`
    margin: 1em 0em 0em 0em;
`;
/**
 * @description props of the filter
 * @interface Props
 */
interface Props {
    text: string;
    measurement: Measurement;
    measurementType: 'node' | 'network' | 'interface';
    setMeasurement: (measurement: Measurement) => void;
}
/**
 * @description filter functional component
 * @type {React.FC}
 */
const MeasurementSelect: React.FC<Props> = ({
    text,
    measurement,
    measurementType,
    setMeasurement,
}) => {
    const [{ data: _measurements, isLoading }] = useApi<Measurement[]>({
        method: Method.GET,
        isAuthorizationNeeded:
            measurementType === 'interface' || measurementType === 'network'
                ? true
                : false,
        initialData: [],
        initialEndpoint: `${measurementType}/measurement`,
    });

    return (
        <>
            <span>{text}</span>
            <SelectContainer>
                <Select
                    options={_measurements.map((measurement) => ({
                        value: measurement,
                        label: translateMeasurement(measurement),
                    }))}
                    isDisabled={isLoading}
                    value={{
                        value: measurement,
                        label: translateMeasurement(measurement),
                    }}
                    isClearable={false}
                    onChange={(newValue) => {
                        const newMeasurement: Option | undefined = newValue as
                            | Option
                            | undefined;
                        if (newMeasurement)
                            setMeasurement(newMeasurement.value as Measurement);
                    }}
                />
            </SelectContainer>
        </>
    );
};

export default MeasurementSelect;

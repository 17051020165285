import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';
import { Nullable, enumFromStringValue } from '@nne-viz/common';

import React from 'react';
import { useLocation } from 'react-router-dom';

class CustomURLSearchParams extends URLSearchParams {

    getEnumString<T>(enm: {[s: string]: T;}, name: string): Nullable<T> {
        const value: Nullable<string> = this.get(name);
        if (value) return enumFromStringValue(enm, value);
        return null;
    }

    getArrayNumber(name: string): Nullable<number[]>{
        const value: Nullable<string> = this.get(name);
        if(value){
            const split: number[] = value.split(',').map(v => parseInt(v));
            return split.includes(NaN) ? null : split;
        }
        return null;
    }

    getDate(name: string): Nullable<NorwayDayjs>{
        const value: Nullable<string> = this.get(name);
        if (!value) return null;
        const valueDayJs: NorwayDayjs = norwaydayjs(value, 'YYYY-MM-DD');
        if (!valueDayJs.isValid()) return null;
        return valueDayJs;
    }

    getDatetime(name: string): Nullable<NorwayDayjs>{
        const value: Nullable<string> = this.get(name);
        if (!value) return null;
        const valueDayJs: NorwayDayjs = norwaydayjs(value, 'YYYY-MM-DDTHH:mm:ssZ');
        if (!valueDayJs.isValid()) return null;
        return valueDayJs;
    }

}

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new CustomURLSearchParams(search), [search]);
};

export {
    useQuery
};
/**
 * @description An enumeration of available scene
 * @type enum
 * @typedef Scene
 */
enum Scene {
    /**
     * @description map scene
     * @type {string}
     */
    MAP = 'map',
    /**
     * @description statistic scene
     * @type {string}
     */
    STATISTICS = 'statistics',
    /**
     * @description comparative scene
     * @type {string}
     */
    COMPARATIVE = 'comparative',
}

export default Scene;

import { BORDER, WHITE } from 'src/constants/colors';
import React, { useState } from 'react';

import { Node } from '@nne-viz/common';
import NodeList from 'src/components/node/NodeList';
import Search from 'src/components/Search';
import styled from 'styled-components';

/**
 * @description node Search list style CSS
 */
const NodeSearchListStyled = styled.div`
    border-color: ${BORDER};
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
`;

/**
 * @description search container style CSS
 */
const SearchContainer = styled.div`
    background: ${WHITE};
    display: flex;
`;
/**
 * @description props for the node search list
 * @interface Props
 */
interface Props {
    /**
     * @description list of nodes
     * @type {Node[]}
     */
    nodes: Node[];
    displayedNodes: Node[];
    setDisplayedNodes: (nodes: Node[]) => void;
    /**
     * @function onClick
     * @description when clicking on node item
     * @param {Node} node
     * @returns void
     */
    onClick?: (node: Node) => void;
    isSelectable: boolean;
    selectedNodes?: Node[];
    setSelectedNodes?: (nodes: Node[]) => void;
}
/**
 * @description node search list functional component for searching node in a list
 * @type {React.FC}
 */
const NodeSearchList: React.FC<Props> = ({
    nodes,
    displayedNodes,
    setDisplayedNodes,
    onClick,
    isSelectable,
    selectedNodes,
    setSelectedNodes,
}: Props) => {
    // the value of the search
    const [search, setSearch] = useState<string>('');

    /**
     * @async
     * @function updateNodeList
     * @description update the node list according to the search value
     * @returns {void}
     */
    const updateNodeList = (search: string): void => {
        // if last character is *
        if (search.endsWith('*'))
        // create new list by copying inital node list and filtering value starting with

            setDisplayedNodes(
                [...nodes].filter(
                    (value) =>
                        value.id
                            .toString()
                            .toLowerCase()
                            .startsWith(
                                search
                                    .substring(0, search.length - 1)
                                    .toLowerCase()
                            ) ||
                        value.address.name
                            .toLowerCase()
                            .startsWith(
                                search
                                    .substring(0, search.length - 1)
                                    .toLowerCase()
                            )
                )
            );
        // create new list by copying inital node list and filtering value including
        else
            setDisplayedNodes(
                [...nodes].filter(
                    (value) =>
                        value.id
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                        value.address.name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                )
            );
    };

    const render = () => {
        return (
            <NodeSearchListStyled>
                <SearchContainer>
                    <Search
                        placeholder="Search by location or node id"
                        value={search}
                        onChange={(value: string) => {
                            setSearch(value);
                            updateNodeList(value);
                        }}
                    />
                </SearchContainer>
                <NodeList
                    nodes={displayedNodes}
                    setNodes={setDisplayedNodes}
                    onClick={onClick}
                    isSelectable={isSelectable}
                    selectedNodes={selectedNodes}
                    setSelectedNodes={setSelectedNodes}
                />
            </NodeSearchListStyled>
        );
    };

    return render();
};

export default NodeSearchList;

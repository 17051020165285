import { BLACK } from 'src/constants/colors';
import Select from 'react-select';
import styled from 'styled-components';

/**
 * @description Select styled CSS
 */
const StyledSelect = styled(Select)`
    color: ${BLACK};
`;

export default StyledSelect;

import { BLACK, BORDER, SUBTHEME } from 'src/constants/colors';
import {
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import {
    Measurement,
    MeasurementStatistic,
    Network,
    Node,
    Nullable,
} from '@nne-viz/common';
import { Method, useApi } from 'src/services/api';
import { NorwayDayjs, norwaydayjs } from 'src/utils/dates';
import React, { useEffect, useRef, useState } from 'react';

import Base from 'src/components/Base';
import FromToDatePicker from 'src/components/datetime/FromToDatePicker';
import MeasurementSelect from 'src/components/select/MeasurementSelect';
import NetworkLineGraph from 'src/components/network/NetworkLineGraph';
import NetworkSwarmGraph from 'src/components/network/NetworkSwarmGraph';
import NetworksSelect from 'src/components/select/NetworksSelect';
import NodeSearchList from 'src/components/node/NodeSearchList';
import Scene from 'src/constants/scene';
import SignInGoogleButton from 'src/components/buttons/SignInGoogleButton';
import StatisticSelect from 'src/components/select/StatisticSelect';
import { measureUnit } from 'src/utils/math';
import styled from 'styled-components';
import { translateMeasurement } from 'src/utils/translate';
import { useAuthorization } from 'src/services/auth';
import { useQuery } from 'src/services/url';

/**
 * @description main container
 */
const Container = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
    }
`;
/**
 * @description caption container
 */
const CaptionContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
`;
/**
 * @description graphic container
 */
const GraphContainer = styled.div`
    display: flex;
    flex: 3;
    justify-content: center;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        min-height: 70vh;
        max-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        flex: 2;
        min-height: 95%;
        max-height: 95%;
    }
`;

interface NodeSearchListContainerProps {
    /**
     * @description is disabled
     * @type {boolean}
     */
    isDisabled: boolean;
}

const NodeSearchListContainer = styled.div<NodeSearchListContainerProps>`
    display: flex;
    flex: 1;
    overflow: auto;

    pointer-events: ${(props) =>
        props.isDisabled ? 'none' : 'auto'};
    opacity: ${(props) =>
        props.isDisabled ? '0.4' : '1'};

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        max-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        max-height: 100vh;
    }
`;
/**
 * @description from to date container
 */
const FromToDateContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 1em 0em 0em 0em;
`;
/**
 * @description select container
 */
const SelectContainer = styled.div`
    flex: 1;
    margin: 1em 0em 0em 0em;
`;
/**
 * @description header container
 */
const HeaderContainer = styled.div`
    background: ${SUBTHEME};
    border-color: ${BORDER};
    border-radius: 5px;
    border-width: 1px;
    color: ${BLACK};
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 0 1em 0;
    overflow: auto;
    padding: 0em 1em 1em 1em;
`;
/**
 * @description select operator container
 */
const SelectOperatorContainer = styled.div`
    align-self: center;
    border: solid;
    border-color: ${BORDER};
    border-radius: 10px;
    border-width: 1px;
    padding: 2em;
`;
/**
 * @description sub container for the graph
 */
const SubGraphContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
`;
/**
 * @description graph title span CSS
 */
const GraphTitle = styled.span`
    font-size: 1em;
    font-weight: bold;
`;

const SignInButtonContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;

interface URLParams {
    measurement: Nullable<Measurement>,
    networks: Nullable<number[]>,
    from: Nullable<NorwayDayjs>,
    to: Nullable<NorwayDayjs>
}

/**
 * @type {React.FC}
 * @description the map dashboard
 */
const Dashboard: React.FC = () => {
    // retrieve URL params
    const query = useQuery();
    const params: URLParams = {
        measurement: query.getEnumString(Measurement, 'measurement'),
        networks: query.getArrayNumber('number'),
        from: query.getDate('from'),
        to: query.getDate('to')
    };
    // retrieve is user is authorized
    const [isAuthorized] = useAuthorization();
    // network state to display data from
    const [networks, setNetworks] = useState<Network[]>([]);
    // measurement state to display data
    const [measurement, setMeasurement] = useState<Measurement>(
        params.measurement ? params.measurement : Measurement.LATENCY
    );
    // statistic to display from measurement
    const [statistic, setStatistic] = useState<MeasurementStatistic>();
    // displayed nodes state in the list and the graph
    const [displayedNodes, setDisplayedNodes] = useState<Node[]>([]);
    // has user displayed specific nodes
    const hasDisplayedSpecificNodes = useRef<boolean>(false);
    // from to state to display data from to
    const [[from, to], setFromTo] = useState<[NorwayDayjs, NorwayDayjs]>([
        params.from ? params.from : norwaydayjs().hour(0).minute(0).second(0).subtract(2, 'day'),
        params.to ? params.to : norwaydayjs().hour(23).minute(59).second(59).subtract(1, 'day'),
    ]);
    // fetched nodes
    const [{ data: nodes, isLoading }, doFetchNodes] = useApi<Node[]>({
        method: Method.POST,
        isAuthorizationNeeded: true,
        initialData: [],
    });
    // refetch node when networks change
    useEffect(() => {
        if (networks.length > 0)
            doFetchNodes({
                endpoint: 'node',
                body: { networks_id: networks.map((network) => network.id) },
            });
    }, [networks]);
    // reset displayed nodes when fetching new nodes
    useEffect(() => {
        setDisplayedNodes((displayedNodes: Node[]) => {
            if (hasDisplayedSpecificNodes.current){
                const nodesId = nodes.map(node => node.id);
                return displayedNodes.filter(node => nodesId.includes(node.id));
            }
            return nodes;
        });
        return () => {
            hasDisplayedSpecificNodes.current = (nodes.length !== 0 && nodes.length !== displayedNodes.length);
        };
    }, [nodes]);
    /**
     * @function renderGraph
     * @description render the graph according to the statistic
     */
    const renderGraph = () => {
        if (networks.length > 0) {
            if (
                statistic === MeasurementStatistic.NODE_AND_NETWORK_DAILY_MEDIAN
            )
                return (
                    <SubGraphContainer>
                        <GraphTitle>
                            Daily median{' '}
                            {translateMeasurement(measurement).toLowerCase()}{' '}
                            per node and operator in {measureUnit(measurement)}
                        </GraphTitle>
                        <NetworkSwarmGraph
                            networks={networks}
                            measurement={measurement}
                            isLoading={isLoading}
                            from={from}
                            to={to}
                            displayedNodes={displayedNodes}
                            statistic={statistic}
                        />
                    </SubGraphContainer>
                );
            if (
                statistic === MeasurementStatistic.NETWORK_DAILY_MEDIAN
            )
                return (
                    <SubGraphContainer>
                        <GraphTitle>
                            Daily median{' '}
                            {translateMeasurement(measurement).toLowerCase()}{' '}
                            per operator in {measureUnit(measurement)}
                        </GraphTitle>
                        <NetworkLineGraph
                            networks={networks}
                            isLoading={isLoading}
                            from={from}
                            to={to}
                            measurement={measurement}
                            statistic={statistic}
                        />
                    </SubGraphContainer>
                );
        } else {
            return (
                <SelectOperatorContainer>
                    <NetworksSelect
                        initialNetworks={params.networks ? params.networks : 'none'}
                        text={'Select operators'}
                        networks={networks}
                        setNetworks={(networks: Network[]) => {
                            setNetworks(networks);
                        }}
                    />
                </SelectOperatorContainer>
            );
        }
    };

    return (
        <Base scene={Scene.STATISTICS}>
            {isAuthorized ? (
                <Container>
                    <CaptionContainer>
                        <HeaderContainer>
                            <SelectContainer>
                                <NetworksSelect
                                    initialNetworks={params.networks ? params.networks : 'none'}
                                    text={'Select operators'}
                                    networks={networks}
                                    setNetworks={(networks: Network[]) => {
                                        setNetworks(networks);
                                    }}
                                />
                            </SelectContainer>
                            <SelectContainer>
                                <MeasurementSelect
                                    text={'Select a measurement'}
                                    measurement={measurement}
                                    measurementType="network"
                                    setMeasurement={setMeasurement}
                                />
                            </SelectContainer>
                            <SelectContainer>
                                <StatisticSelect
                                    text={'Select a statistic'}
                                    measurement={measurement}
                                    statistic={statistic}
                                    setStatistic={setStatistic}
                                />
                            </SelectContainer>
                            <FromToDateContainer>
                                <FromToDatePicker
                                    values={[from, to]}
                                    setValues={([from, to]: [
                                        NorwayDayjs,
                                        NorwayDayjs
                                    ]) => {
                                        setFromTo([
                                            from, to
                                        ]);
                                    }}
                                />
                            </FromToDateContainer>
                        </HeaderContainer>
                        <NodeSearchListContainer isDisabled={isLoading}>
                            {' '}
                            <NodeSearchList
                                nodes={nodes}
                                displayedNodes={displayedNodes}
                                setDisplayedNodes={setDisplayedNodes}
                                isSelectable={false}
                            />
                        </NodeSearchListContainer>
                    </CaptionContainer>
                    <GraphContainer>{renderGraph()}</GraphContainer>
                </Container>
            ) : (
                <SignInButtonContainer>
                    <SignInGoogleButton />
                </SignInButtonContainer>
            )}
        </Base>
    );
};

export default Dashboard;

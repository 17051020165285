"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description enumeration of existing statistics
 */
var Measurement;
(function (Measurement) {
    Measurement["LATENCY"] = "latency";
    Measurement["PACKET_LOSS"] = "packet_loss";
    Measurement["UPLOAD_SPEED"] = "upload_speed";
    Measurement["DOWNLOAD_SPEED"] = "download_speed";
})(Measurement || (Measurement = {}));
exports.default = Measurement;

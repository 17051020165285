import React, { useState } from 'react';

import { Node } from '@nne-viz/common';
import NodeItem from 'src/components/node/NodeItem';
import TableBody from 'src/components/table/TableBody';
import TableFooter from 'src/components/table/TableFooter';
import TableHeader from 'src/components/table/TableHeader';
import styled from 'styled-components';

/**
 * @description the keys to display for a node
 * @enum {string}
 */
enum NodeKeys {
    Node = 'id',
    Location = 'address',
    Status = 'status',
}

/**
 * @description node list style CSS
 */
const NodeListStyled = styled.table`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
`;
/**
 * @description props of the node list
 * @interface Props
 */
interface Props {
    /**
     * @description the nodes of the list
     * @type {Node[]}
     */
    nodes: Node[];
    /**
     * @function setNodes
     * @description set the nodes from parameter
     * @param {Node[]} nodes nodes to set
     * @returns {void}
     */
    setNodes: (nodes: Node[]) => void;
    /**
     * @function onClick
     * @description behavior when clicking on node item
     * @param {Node} nodeId node id of the click
     * @returns void
     */
    onClick?: (node: Node) => void;
    isSelectable: boolean;
    selectedNodes?: Node[];
    setSelectedNodes?: (nodes: Node[]) => void;
}
/**
 * @description Node list to display a list of nodes functional component
 * @type {React.FC}
 */
const NodeList: React.FC<Props> = ({
    nodes,
    setNodes,
    onClick,
    isSelectable,
    selectedNodes,
    setSelectedNodes,
}: Props) => {
    // ascending state for ordering the nodes
    const [ascending, setAscending] = useState<boolean>(false);
    /**
     * @function sort
     * @param {string} header value to sort the node from
     * @returns {void}
     */
    const sort = (header: string): void => {
        // IMPORTANT: Do array spreading for creating a copy
        const sortedList = [...nodes];
        // Retrieve the key
        const key: NodeKeys = NodeKeys[header as keyof typeof NodeKeys];
        // Defines from bottom to top or top to bottom
        setAscending(!ascending);
        // Switch on the key value and sort the list
        switch (key) {
            case NodeKeys.Location:
                sortedList.sort((a, b) =>
                    ascending
                        ? a[NodeKeys.Location].name > b[NodeKeys.Location].name
                            ? -1
                            : 1
                        : a[NodeKeys.Location].name < b[NodeKeys.Location].name
                        ? -1
                        : 1
                );
                break;
            default:
                sortedList.sort((a, b) =>
                    ascending
                        ? a[key as keyof Node] > b[key as keyof Node]
                            ? -1
                            : 1
                        : a[key as keyof Node] < b[key as keyof Node]
                        ? -1
                        : 1
                );
        }
        // set the nodes
        setNodes(sortedList);
    };

    const render = () => {
        return (
            <NodeListStyled>
                <TableHeader
                    values={Object.keys(NodeKeys)}
                    sort={sort}
                    isSelectable={false}
                />
                <TableBody>
                    {nodes.map((node, index) => (
                        <NodeItem
                            key={node.id}
                            node={node}
                            onClick={onClick}
                            isLastIndex={index == nodes.length - 1}
                            isSelectable={false}
                        />
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
            </NodeListStyled>
        );
    };

    const renderSelectable = (
        selectedNodes: Node[],
        setSelectedNodes: (nodes: Node[]) => void
    ) => {
        const onSelectNode = (node: Node) => {
            setSelectedNodes(
                selectedNodes.includes(node)
                    ? [...selectedNodes].filter((xNode) => xNode.id !== node.id)
                    : [node, ...selectedNodes]
            );
        };

        const onSelectAllNodes = () => {
            setSelectedNodes(selectedNodes.length > 0 ? [] : [...nodes]);
        };

        return (
            <NodeListStyled>
                <TableHeader
                    values={Object.keys(NodeKeys)}
                    sort={sort}
                    isSelectable={true}
                    isSelected={
                        nodes.length > 0 &&
                        selectedNodes.length === nodes.length
                    }
                    isIndeterminate={
                        selectedNodes.length > 0 &&
                        selectedNodes.length !== nodes.length
                    }
                    onSelect={onSelectAllNodes}
                />
                <TableBody>
                    {nodes.map((node, index) => (
                        <NodeItem
                            key={node.id}
                            node={node}
                            isLastIndex={index == nodes.length - 1}
                            onClick={(node: Node) => onSelectNode(node)}
                            isSelectable={true}
                            isSelected={selectedNodes
                                .map((xNode) => xNode.id)
                                .includes(node.id)}
                            onSelect={(node: Node) => onSelectNode(node)}
                        />
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
            </NodeListStyled>
        );
    };

    return isSelectable &&
        selectedNodes !== undefined &&
        setSelectedNodes !== undefined
        ? renderSelectable(selectedNodes, setSelectedNodes)
        : render();
};

export default NodeList;

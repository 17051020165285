import { BLACK, HOVER } from 'src/constants/colors';
import React, { useState } from 'react';

import Cross from 'src/img/Cross';
import styled from 'styled-components';

/**
 * @description button style CSS
 */
const ButtonStyled = styled.button`
    align-self: center;
    background: transparent;
    border: none;
    display: flex;
`;
/**
 * @description props of the close button
 * @interface Props
 */
interface Props {
    onClick: () => void;
}
/**
 * @description cross button functional component
 * @type {React.FC}
 */
const CrossButton: React.FC<Props> = ({ onClick }) => {
    const [isHover, setHover] = useState<boolean>(false);

    return (
        <ButtonStyled
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Cross fill={isHover ? HOVER : BLACK} />
        </ButtonStyled>
    );
};

export default CrossButton;

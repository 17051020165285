import React, { useState } from 'react';

import IInteraceItem from 'src/components/iinterface/IInterfaceItem';
import { IInterface } from '@nne-viz/common';
import TableBody from 'src/components/table/TableBody';
import TableFooter from 'src/components/table/TableFooter';
import TableHeader from 'src/components/table/TableHeader';
import styled from 'styled-components';

/**
 * @description the keys to display for a node
 * @enum {string}
 */
enum IInterfaceKeys {
    Node = 'nodeId',
    Provider = 'provider',
    Location = 'location',
    Status = 'status',
}

/**
 * @description node list style CSS
 */
const InterfaceListStyled = styled.table`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
`;
/**
 * @description props of the interface list
 * @interface Props
 */
interface Props {
    /**
     * @description the interfaces of the list
     * @type {IInterface[]}
     */
    iinterfaces: IInterface[];
    /**
     * @function setInterfaces
     * @description set the interfaces from parameter
     * @param {IInterface[]} interfaces to set
     * @returns {void}
     */
    setIInterfaces: (interfaces: IInterface[]) => void;

    isSelectable: boolean;
    selectedIInterfaces?: IInterface[];
    setSelectedIInterfaces?: React.Dispatch<React.SetStateAction<IInterface[]>>;
}
/**
 * @description Node list to display a list of nodes functional component
 * @type {React.FC}
 */
const InterfaceList: React.FC<Props> = ({
    iinterfaces,
    setIInterfaces,
    isSelectable,
    selectedIInterfaces,
    setSelectedIInterfaces,
}: Props) => {
    // ascending state for ordering the nodes
    const [ascending, setAscending] = useState<boolean>(false);
    /**
     * @function sort
     * @param {string} header value to sort the interface from
     * @returns {void}
     */
    const sort = (header: string): void => {
        // IMPORTANT: Do array spreading for creating a copy
        const sortedList = [...iinterfaces];
        // Retrieve the key
        const key: IInterfaceKeys =
            IInterfaceKeys[header as keyof typeof IInterfaceKeys];
        // Defines from bottom to top or top to bottom
        setAscending(!ascending);
        // Switch on the key value and sort the list
        switch (key) {
            default:
                sortedList.sort((a, b) =>
                    ascending
                        ? a[key as keyof IInterface] >
                          b[key as keyof IInterface]
                            ? -1
                            : 1
                        : a[key as keyof IInterface] <
                          b[key as keyof IInterface]
                        ? -1
                        : 1
                );
        }
        // set the interfaces
        setIInterfaces(sortedList);
    };

    const render = () => {
        return (
            <InterfaceListStyled>
                <TableHeader
                    values={Object.keys(IInterfaceKeys)}
                    sort={sort}
                    isSelectable={false}
                />
                <TableBody>
                    {iinterfaces.map((iinterface, index) => (
                        <IInteraceItem
                            key={`${iinterface.nodeId}${iinterface.networkId}`}
                            iinterface={iinterface}
                            isSelectable={false}
                            isLastIndex={index == iinterfaces.length - 1}
                        />
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
            </InterfaceListStyled>
        );
    };

    const renderSelectable = (
        selectedIInterfaces: IInterface[],
        setSelectedIInterfaces: React.Dispatch<React.SetStateAction<IInterface[]>>
    ) => {
        const onSelectIInterface = (iinterface: IInterface) => {
            setSelectedIInterfaces((previousSelectedIInterfaces: IInterface[]) => {
                return previousSelectedIInterfaces.map(previousSelectedIInterface => `${previousSelectedIInterface.nodeId}${previousSelectedIInterface.networkId}`).includes(`${iinterface.nodeId}${iinterface.networkId}`)
                    ? [...previousSelectedIInterfaces].filter(
                        (xIInterface) =>
                            !(
                                xIInterface.nodeId === iinterface.nodeId &&
                                  xIInterface.networkId === iinterface.networkId
                            )
                    )
                    : [iinterface, ...previousSelectedIInterfaces];
            });
        };

        const onSelectAllNodes = () => {
            setSelectedIInterfaces(
                selectedIInterfaces.length > 0 ? [] : [...iinterfaces]
            );
        };

        return (
            <InterfaceListStyled>
                <TableHeader
                    values={Object.keys(IInterfaceKeys)}
                    sort={sort}
                    isSelectable={true}
                    isSelected={
                        iinterfaces.length > 0 &&
                        selectedIInterfaces.length === iinterfaces.length
                    }
                    isIndeterminate={
                        selectedIInterfaces.length > 0 &&
                        selectedIInterfaces.length !== iinterfaces.length
                    }
                    onSelect={onSelectAllNodes}
                />
                <TableBody>
                    {iinterfaces.map((iinterface, index) => (
                        <IInteraceItem
                            key={`${iinterface.nodeId}${iinterface.networkId}`}
                            iinterface={iinterface}
                            isLastIndex={index == iinterfaces.length - 1}
                            isSelectable={true}
                            isSelected={selectedIInterfaces
                                .map(
                                    (xIInterface) =>
                                        `${xIInterface.nodeId}${xIInterface.networkId}`
                                )
                                .includes(
                                    `${iinterface.nodeId}${iinterface.networkId}`
                                )}
                            onSelect={(iinterface: IInterface) =>
                                onSelectIInterface(iinterface)
                            }
                        />
                    ))}
                </TableBody>
                <TableFooter></TableFooter>
            </InterfaceListStyled>
        );
    };

    return isSelectable &&
        selectedIInterfaces !== undefined &&
        setSelectedIInterfaces !== undefined
        ? renderSelectable(selectedIInterfaces, setSelectedIInterfaces)
        : render();
};

export default InterfaceList;

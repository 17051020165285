import { BLACK, TEXT_HOVER } from 'src/constants/colors';
import React, { useRef } from 'react';

import styled from 'styled-components';

/**
 * @description Props for Table Header Cell
 * @interface TableHeaderCellSelectProps
 */
interface TableHeaderCellSelectProps {
    /**
     * @description column of the cell refer to CSS
     * @type {string}
     */
    grid_column: string;
}

/**
 * @description Table header style CSS
 */
const TableHeaderCellSelectStyled = styled.th<TableHeaderCellSelectProps>`
    color: ${BLACK};
    display: flex;
    grid-row: 1 / 1;
    grid-column: ${(props) =>
        props.grid_column ? props.grid_column : '1 / 1'};
    text-align: end;
    padding: 1em;

    &:hover {
        color: ${TEXT_HOVER};
    }
`;

/**
 * @description Props for TableHeaderCell
 * @interface Props
 */
interface Props {
    /**
     * @description CSS for column
     * @type {string}
     */
    grid_column: string;
    /**
     * @function onClick
     * @description on click function
     * @return {void}
     */
    onClick: () => void;
    /**
     * @description text to display
     * @type {string}
     */
    isSelected: boolean;
    isIndeterminate: boolean;
    onSelect: () => void;
}

/**
 * @description Table Header Cell functional component
 * @type {React.FC}
 */
const TableHeaderSelectCell: React.FC<Props> = ({
    grid_column,
    onClick,
    isSelected,
    isIndeterminate,
    onSelect,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    if (inputRef.current) inputRef.current.indeterminate = isIndeterminate;

    return (
        <TableHeaderCellSelectStyled
            grid_column={grid_column}
            onClick={onClick}
        >
            <input
                type="checkbox"
                checked={isSelected}
                onChange={onSelect}
                ref={inputRef}
            />
            <span>Select all</span>
        </TableHeaderCellSelectStyled>
    );
};

export default TableHeaderSelectCell;

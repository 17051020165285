import React from 'react';
import { RotatingSquare } from 'react-loader-spinner';
import { SUBTHEME } from 'src/constants/colors';
import styled from 'styled-components';

/**
 * @description square loader container style CSS
 */
const SquareLoaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;
/**
 * @description square loader fonctional component to display when loading
 * @type {React.FC}
 */
const SquareLoader: React.FC = () => {
    return (
        <RotatingSquare
            visible={true}
            height="100"
            width="100"
            strokeWidth="4"
            ariaLabel="rotating-square-loading"
            wrapperStyle={{}}
            wrapperClass="rotating-square-wrapper"
            color={SUBTHEME}
        />
    );
};

export default SquareLoader;

export { SquareLoaderContainer };

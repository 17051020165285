import { BORDER, Status, WHITE } from 'src/constants/colors';

import Circle from 'src/img/Circle';
import { Node } from '@nne-viz/common';
import React from 'react';
import styled from 'styled-components';

/**
 * @description table row props
 * @interface TableRowProps
 */
interface TableRowProps {
    /**
     * @description the length of the column
     * @type {number}
     */
    columnLength: number;
    /**
     * @description is last index
     * @type {boolean}
     */
    isLastIndex: boolean;
}
/**
 * @description table row style CSS
 */
const TableRow = styled.tr<TableRowProps>`
    background: ${WHITE};
    border: solid;
    border-color: ${BORDER};
    border-width: 0 0 ${(props) => (props.isLastIndex ? '0' : '1')}px 0;
    display: grid;
    grid-template-columns: repeat(
        ${(props) => (props.columnLength ? props.columnLength : '1')},
        1fr
    );

    &:hover {
        background: ${BORDER};
    }
`;
/**
 * @description table data cell props
 * @interface TableDataCellProps
 */
interface TableDataCellProps {
    /**
     * @description column of the cell
     * @type {string}
     */
    grid_column: string;
}
/**
 * @description table data cell style CSS
 */
const TableDataCell = styled.td<TableDataCellProps>`
    grid-row: 1 / 1;
    grid-column: ${(props) =>
        props.grid_column ? props.grid_column : '1 / 1'};
    overflow: auto;
    padding: 1em;
`;
/**
 * @description props of the node item component
 * @interface Props
 */
interface Props {
    /**
     * @description the node of the node item
     * @type {Node}
     */
    node: Node;
    /**
     * @function onClick
     * @description behavior when clicking on node
     * @param {Node} node
     */
    onClick?: (node: Node) => void;
    /**
     * @description is last item
     * @type {boolean}
     */
    isLastIndex: boolean;
    /**
     * @description is selectable item
     * @type {boolean}
     */
    isSelectable: boolean;
    /**
     * @description is item selected
     * @type {boolean}
     */
    isSelected?: boolean;
    /**
     * @function onSelect
     * @description behavior when selecting node
     * @param {Node} node
     */
    onSelect?: (node: Node) => void;
}
/**
 * @description node item functional component to display in a list of node
 * @type {React.FC}
 */
const NodeItem: React.FC<Props> = ({
    node,
    onClick,
    isSelectable,
    isSelected,
    isLastIndex,
    onSelect,
}: Props) => {
    const render = () => {
        return (
            <>
                <TableRow
                    columnLength={3}
                    isLastIndex={isLastIndex}
                    onClick={() => (onClick ? onClick(node) : undefined)}
                >
                    <TableDataCell grid_column="1 / 2">{node.id}</TableDataCell>
                    <TableDataCell grid_column="2 / 3">
                        {node.address.name}
                    </TableDataCell>
                    <TableDataCell grid_column="3 / 4">
                        <Circle
                            fill={Status[node.status as keyof typeof Status]}
                            size={10}
                        />
                    </TableDataCell>
                </TableRow>
            </>
        );
    };

    const renderSelectable = (
        isSelected: boolean,
        onSelect: (node: Node) => void
    ) => {
        return (
            <>
                <TableRow
                    isLastIndex={isLastIndex}
                    columnLength={4}
                    onClick={() => (onClick ? onClick(node) : undefined)}
                >
                    <TableDataCell grid_column="1 / 2">
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => onSelect(node)}
                        />
                    </TableDataCell>
                    <TableDataCell grid_column="2 / 3">{node.id}</TableDataCell>
                    <TableDataCell grid_column="3 / 4">
                        {node.address.name}
                    </TableDataCell>
                    <TableDataCell grid_column="4 / 5">
                        <Circle
                            fill={Status[node.status as keyof typeof Status]}
                            size={10}
                        />
                    </TableDataCell>
                </TableRow>
            </>
        );
    };

    if (isSelectable && isSelected !== undefined && onSelect !== undefined)
        return renderSelectable(isSelected, onSelect);
    else return render();
};

export default NodeItem;

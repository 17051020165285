import { HOVER, SUBTHEME } from 'src/constants/colors';
import React, { useState } from 'react';

import Refresh from 'src/img/Refresh';
import styled from 'styled-components';

/**
 * @description button style CSS
 */
const ButtonStyled = styled.button`
    background: transparent;
    border: none;
`;
/**
 * @description props for refresh button
 * @type {Props}
 */
type Props = {
    onClick: () => void;
};
/**
 * @description refresh button functional component
 * @type {React.FC}
 */
const RefreshButton: React.FC<Props> = ({ onClick }) => {
    // state for filling the button
    const [fill, setFill] = useState<string>(SUBTHEME);
    return (
        <ButtonStyled
            onClick={onClick}
            onMouseEnter={() => setFill(HOVER)}
            onMouseLeave={() => setFill(SUBTHEME)}
        >
            <Refresh fill={fill} />
        </ButtonStyled>
    );
};

export default RefreshButton;

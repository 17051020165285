import { MAIN_THEME, WHITE } from 'src/constants/colors';

import React from 'react';
import SimulaMet from 'src/img/SimulaMet';
import styled from 'styled-components';

/**
 * @description Footer styled CSS
 */
const FooterStyled = styled.div`
    align-items: center;
    background: ${MAIN_THEME};
    color: ${WHITE};
    display: flex;
    padding: 0.3em 2em 0.3em 1em;
    position: relative;
    z-index: 0;
`;
/**
 * @description Span styled CSS
 */
const Span = styled.span``;
/**
 * @description Container styled CSS
 */
const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;
/**
 * @description Footer functional component
 * @type {React.FC}
 */
const Footer: React.FC = () => {
    return (
        <FooterStyled>
            <Span>© Simula Research Laboratory</Span>
            <Container>
                <SimulaMet />
            </Container>
        </FooterStyled>
    );
};

export default Footer;

import { BORDER, HOVER } from 'src/constants/colors';

import Google from 'src/img/Google';
import { REACT_APP_API_URL } from 'src/constants/config';
import React from 'react';
import styled from 'styled-components';

/**
 * @description button style CSS
 */
const ButtonStyled = styled.button`
    align-items: center;
    background: white;
    border: solid;
    border-color: ${BORDER};
    border-radius: 10px;
    border-width: 3px;
    display: flex;
    padding: 1em;

    &:hover {
        border-color: ${HOVER};
    }
`;

const Span = styled.span`
    font-size: 1.2em;
    margin: 0em 0em 0em 1em;
`;
/**
 * @description sign in with google button functional component
 * @type {React.FC}
 */
const SignInGoogleButton: React.FC = () => {
    return (
        <ButtonStyled
            onClick={() => {
                const location = encodeURIComponent(window.location.href);
                window.location.href = `${REACT_APP_API_URL}auth/google/login?state=${location}`;
            }}
        >
            <Google />
            <Span>Sign in with Google</Span>
        </ButtonStyled>
    );
};

export default SignInGoogleButton;

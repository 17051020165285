import { Bars } from 'react-loader-spinner';
import { MAIN_THEME } from 'src/constants/colors';
import React from 'react';
import styled from 'styled-components';

/**
 * @description bar loader container style CSS
 */
const BarLoaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
`;
/**
 * @description bar loader functional component to display when loading
 * @type {React.FC}
 */
const BarLoader: React.FC = () => {
    return (
        <Bars
            visible={true}
            height="40"
            width="40"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
            color={MAIN_THEME}
        />
    );
};

export default BarLoader;

export { BarLoaderContainer };

import { Method, useApi } from 'src/services/api';

import { Network } from '@nne-viz/common';
import Option from 'src/components/select/Option';
import React from 'react';
import Select from 'src/components/select/Select';
import styled from 'styled-components';
import { translateProvider } from 'src/utils/translate';

/**
 * @description select container style CSS
 */
const SelectContainer = styled.div`
    margin: 1em 0em 0em 0em;
`;
/**
 * @description props of the filter
 * @interface Props
 */
interface Props {
    isClearable: boolean;
    /**
     * @description network
     * @type {Network | undefined}
     */
    network: Network | undefined;
    /**
     * @function setNetwork
     * @param {Network} network
     * @returns {void}
     */
    setNetwork: (network: Network | undefined) => void;
    /**
     * @description text
     * @type {string}
     */
    text: string;
}
/**
 * @description filter functional component
 * @type {React.FC}
 */
const NetworkSelect: React.FC<Props> = ({
    text,
    network,
    setNetwork,
    isClearable,
}) => {
    const [{ data: _networks, isLoading }] = useApi<Network[]>({
        method: Method.GET,
        isAuthorizationNeeded: false,
        initialData: [],
        initialEndpoint: 'network',
    });

    return (
        <>
            <span>{text}</span>
            <SelectContainer>
                <Select
                    options={_networks.map((network) => ({
                        value: network.id,
                        label: translateProvider(network.provider),
                    }))}
                    isDisabled={isLoading}
                    value={
                        network
                            ? {
                                value: network.id,
                                label: translateProvider(network.provider),
                            }
                            : undefined
                    }
                    isClearable={isClearable}
                    onChange={(newNetwork) => {
                        if (newNetwork !== undefined) {
                            const aux: Network | undefined = _networks.find(
                                (network) =>
                                    network.id ===
                                    (newNetwork as Option | undefined)?.value
                            );
                            setNetwork(aux);
                        } else setNetwork(undefined);
                    }}
                />
            </SelectContainer>
        </>
    );
};

export default NetworkSelect;

/**
 * @description URL used by the react application to reach the API
 * @type string | undefined
 */
const REACT_APP_API_URL: string | undefined = process.env.REACT_APP_API_URL;
/**
 * @description font size on desktop
 * @type {number}
 */
const FONT_SIZE_DESKTOP = 1;
/**
 * @description font size on phone
 * @type {number}
 */
const FONT_SIZE_PHONE = 0.6;
/**
 * @description max width for phone portrait
 * @type {number}
 */
const MAX_WIDTH_PHONE_PORTRAIT = 600;
/**
 * @description max width for phone landscape
 * @type {number}
 */
const MAX_WIDTH_PHONE_LANDSCAPE = 950;
/**
 * @description max width small desktop screens
 * @type {}
 */
export {
    REACT_APP_API_URL,
    FONT_SIZE_DESKTOP,
    FONT_SIZE_PHONE,
    MAX_WIDTH_PHONE_PORTRAIT,
    MAX_WIDTH_PHONE_LANDSCAPE,
};

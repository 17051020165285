import { BORDER, WHITE } from 'src/constants/colors';

import { Node } from '@nne-viz/common';
import { translateStatus } from 'src/utils/translate';

/**
 * @function createHoverNodeCaption
 * @description create the node caption when mouse hover a node element on the map
 * @returns {HTMLElement}
 */
const createHoverNodeCaption = (): HTMLElement => {
    const nodeCaption = document.createElement('div');
    nodeCaption.className = 'node_caption';
    nodeCaption.style.background = WHITE;
    nodeCaption.style.padding = '0.5em 0.5em 0.5em 0.5em';
    nodeCaption.style.borderRadius = '5px 5px 5px 5px';
    nodeCaption.style.border = 'solid';
    nodeCaption.style.borderWidth = '1px';
    nodeCaption.style.borderColor = BORDER;
    nodeCaption.style.display = 'none';
    return nodeCaption;
};
/**
 * @function createFocusNodeCaption
 * @description create the caption for the selected node
 * @param onClick function to trigger on click
 * @returns {HTMLElement}
 */
const createFocusNodeCaption = (): HTMLElement => {
    const nodeCaption = document.createElement('div');
    nodeCaption.className = 'node_caption';
    nodeCaption.style.background = WHITE;
    nodeCaption.style.padding = '0.5em 0.5em 0.5em 0.5em';
    nodeCaption.style.borderRadius = '5px 5px 5px 5px';
    nodeCaption.style.border = 'solid';
    nodeCaption.style.borderWidth = '1px';
    nodeCaption.style.borderColor = BORDER;
    nodeCaption.style.display = 'none';

    nodeCaption.addEventListener('mouseover', () => {
        nodeCaption.style.backgroundColor = BORDER;
    });

    nodeCaption.addEventListener('mouseout', () => {
        nodeCaption.style.backgroundColor = WHITE;
    });

    return nodeCaption;
};
/**
 * @function setNodeCaption
 * @description set the node caption for the specific node
 * @param {HTMLElement} caption
 * @param {Node} node
 * @returns {void}
 */
const setNodeCaption = (caption: HTMLElement, node: Node): void => {
    caption.innerHTML = `
        <span>Node: ${node.id}</span>
        <br/>
        <span>Location: ${node.address.name}</span>
        <br />
        <span>Status: ${translateStatus(node.status)}</span>
    `;
};

export { createHoverNodeCaption, setNodeCaption, createFocusNodeCaption };

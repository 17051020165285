import CycleLoader, {
    CycleLoaderContainer,
} from 'src/components/loader/CycleLoader';
import {
    MAX_WIDTH_PHONE_LANDSCAPE,
    MAX_WIDTH_PHONE_PORTRAIT,
} from 'src/constants/config';
import { Method, useApi } from 'src/services/api';
import { Network, Node } from '@nne-viz/common';
import React, { useEffect, useRef, useState } from 'react';

import Base from 'src/components/Base';
import Map from 'src/components/map/Map';
import MapHeader from 'src/components/map/header/MapHeader';
import NodeModal from 'src/components/node/NodeModal';
import NodeSearchList from 'src/components/node/NodeSearchList';
import Scene from 'src/constants/scene';
import { norwaydayjs } from 'src/utils/dates';
import styled from 'styled-components';

/**
 * @description Main container for the map
 */
const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    z-index: 0;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        flex-direction: column;
        overflow: auto;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_LANDSCAPE + 1}px) {
        overflow: auto;
    }
`;

const NodeSearchListContainer = styled.div`
    display: flex;
    flex: 1;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        max-height: 70vh;
    }

    @media (max-width: ${MAX_WIDTH_PHONE_LANDSCAPE}px) and (min-width: ${MAX_WIDTH_PHONE_PORTRAIT +
        1}px) {
        max-height: 70vh;
    }
`;

const MapContainer = styled.div`
    display: flex;
    flex: 2;

    @media (max-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        min-height: 70vh;
        margin-bottom: 1em;
    }

    @media (min-width: ${MAX_WIDTH_PHONE_PORTRAIT}px) {
        margin-right: 1em;
    }
`;

/**
 * @description Contain that is not interactable
 */
const DisableContainer = styled(Container)`
    pointer-events: none;
    opacity: 0.4;
`;

/**
 * @type {React.FC}
 * @description the map dashboard
 */
const Dashboard: React.FC = () => {
    // State array of the nodes displayed in the list
    const [displayedNodes, setDisplayedNodes] = useState<Node[]>([]);
    // State for node modal
    const [nodeModal, setNodeModal] = useState<Node | undefined>();
    // State Network ID selected on the header
    const [network, setNetwork] = useState<Network>();
    // Disabled state
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const [{ data: nodes, isLoading }, doFetchNodes] = useApi<Node[]>({
        method: Method.GET,
        isAuthorizationNeeded: false,
        initialData: [],
        initialEndpoint: network?.id ? `node?network_id=${network.id}` : 'node',
    });
    // Ref for knowing if the functional component is mounted
    const isMount = useRef<boolean>(false);

    // useEffect run when the networkId is updated
    useEffect(() => {
        if (isMount.current) setDisabled(true);
        doFetchNodes({
            endpoint: network?.id ? `node?network_id=${network.id}` : 'node',
        });
    }, [network]);

    useEffect(() => {
        if (isMount.current) setDisabled(false);
        setDisplayedNodes(nodes);
        isMount.current = true;
    }, [nodes]);

    /**
     * @function renderNodeModal
     * @description display for the modal of the node selected
     * @returns {JSX.Element | null}
     */
    const renderNodeModal = (): JSX.Element | null => {
        return nodeModal ? (
            <NodeModal
                close={() => {
                    document.body.style.overflowY = 'auto';
                    setNodeModal(undefined);
                }}
                getDatetime={() => norwaydayjs()}
                nodeId={nodeModal.id}
                measurement={undefined}
            />
        ) : null;
    };

    /**
     * @function renderDashboard
     * @description Render the dashboard according to loading and disabled
     * @returns {JSX.Element}
     */
    const renderDashboard = (): JSX.Element => {
        return isDisabled ? (
            <DisableContainer>
                <MapContainer>
                    <Map nodes={nodes} setSelectedNode={setNodeModal} />
                </MapContainer>

                <NodeSearchList
                    nodes={nodes}
                    displayedNodes={displayedNodes}
                    setDisplayedNodes={setDisplayedNodes}
                    onClick={setNodeModal}
                    isSelectable={false}
                />
            </DisableContainer>
        ) : isLoading ? (
            <CycleLoaderContainer>
                <CycleLoader />
            </CycleLoaderContainer>
        ) : (
            <Container>
                {renderNodeModal()}
                <MapContainer>
                    <Map nodes={nodes} setSelectedNode={setNodeModal} />
                </MapContainer>
                <NodeSearchListContainer>
                    <NodeSearchList
                        nodes={nodes}
                        displayedNodes={displayedNodes}
                        setDisplayedNodes={setDisplayedNodes}
                        onClick={(node: Node) => {
                            document.body.style.overflowY = 'hidden';
                            setNodeModal(node);
                        }}
                        isSelectable={false}
                    />
                </NodeSearchListContainer>
            </Container>
        );
    };

    return (
        <Base scene={Scene.MAP}>
            <MapHeader network={network} setNetwork={setNetwork} />
            {renderDashboard()}
        </Base>
    );
};

export default Dashboard;

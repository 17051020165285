import { Measurement } from '@nne-viz/common';

/**
 * @function getMeasureUnit
 * @description Returns the measure unit according to the value display in the graphic
 * @param {Measurement} value value of the graphic display
 * @returns {string} Measure unit
 */
const measureUnit = (value: Measurement): string => {
    switch (value) {
        case Measurement.LATENCY:
            return 'ms';
        case Measurement.PACKET_LOSS:
            return '%';
        case Measurement.UPLOAD_SPEED:
            return 'Mb/s';
        case Measurement.DOWNLOAD_SPEED:
            return 'Mb/s';
        default:
            return value;
    }
};

export { measureUnit };
